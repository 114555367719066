import styled from "styled-components";
import { colors, FONT } from "../../styles";

export const Container = styled.div`
  
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
    
`

export const BlurContainer = styled.div`

  width: 100%;
  height: 100%;

  background-color: ${colors.black};
  opacity: 0.7;
`

export const Modal = styled.div`

  background-color: ${colors.white};

  width: 500px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 20px;
  max-height: 100vh;
  padding: 20px;
`

export const Label = styled.label`

  ${FONT({size: '20px'})}

`

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Image = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;