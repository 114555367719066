import {call, put, takeLatest} from 'redux-saga/effects';

import { generateSuccessToast, i18n} from '../../common';
import { getAccessToken } from '../admin';
import { showToastAction } from '../toasts';
import { TDataWrapper, TResponse} from '../types';

import { placeActions } from './actions';
import { ApiPlaceService } from './api.service';
import { EPlacesResponse } from './consts';
import {
	TCreatePlacePyaload,
	TEditPlacePayload,
	TGenerateQrcodePayload,
	TGetPlacePayload,
	TGetPlacesPayload,
	TGetTransactionsPyaload,
	TRemoveDocumentPayload,
	TRemovePhotoPayload,
	TRemovePlacePayload,
} from './types';

function* getListWorker({ payload }: TDataWrapper<TGetPlacesPayload>) {
	yield put(placeActions.setState({ loading: true }));
	const token: string = yield getAccessToken();
	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.getList],
			{
				authorization: token,
				...payload,
			});
		if (response.data)
			yield put(placeActions.setList({ places: { data: response.data.places, total: response.data.total } }));
	} catch (e) {}

	yield put(placeActions.setState({ loading: false }));
}

function* countWorker() {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.getCount], {token});
		if (response.data) yield put(placeActions.setCount({count: response.data}));
	} catch (e) {
	}
	yield put(placeActions.setState({loading: false}));
}

function* createWorker({payload}: TDataWrapper<TCreatePlacePyaload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {data, images, documents} = payload;

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.create], {token, data});
		if (response.data) {
			for (let file of images) {
				const data = new FormData();
				data.append('image', file);
				yield call([ApiPlaceService, ApiPlaceService.addPhoto], {token, _id: response.data._id, data});
			}

			for (let file of documents) {
				const data = new FormData();
				data.append('image', file);
				yield call([ApiPlaceService, ApiPlaceService.addDocument], {token, _id: response.data._id, data});
			}

			yield put(placeActions.set({place: response.data}));
			yield put(placeActions.setState({loading: false, response: EPlacesResponse.CREATE}));
			yield put(showToastAction.request(generateSuccessToast(i18n.t('place.was.successfuly.created') as string)));
		}
	} catch (e) {
		yield put(placeActions.setState({loading: false, response: EPlacesResponse.ERROR}));
	}
}

function* getWorker({payload}: TDataWrapper<TGetPlacePayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id} = payload;

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.get], {token, _id});
		if (response.data) yield put(placeActions.set({place: response.data}));
		yield put(placeActions.setState({loading: false}));
	} catch (e) {
		yield put(placeActions.setState({loading: false, response: EPlacesResponse.ERROR}));
	}
}

function* editWorker({payload}: TDataWrapper<TEditPlacePayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id, data, images, removedImages, documents, removedDocuments} = payload;

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.edit], {token, _id, data});
		for (let file of images) {
			const data = new FormData();
			data.append('image', file);
			yield call([ApiPlaceService, ApiPlaceService.addPhoto], {token, _id: response.data._id, data});
		}

		for (let photo_id of removedImages) {
			// yield call([ApiPlaceService, ApiPlaceService.removePhoto], { token, _id: response.data._id, photo_id })
		}

		for (let file of documents) {
			const data = new FormData();
			data.append('image', file);
			yield call([ApiPlaceService, ApiPlaceService.addDocument], {token, _id: response.data._id, data});
		}

		for (let document_id of removedDocuments) {
			// yield call([ApiPlaceService, ApiPlaceService.removeDocument], { token, _id: response.data._id, document_id })
		}

		if (response.data) {
			yield put(placeActions.set({place: null}));
			window.location.reload();
		}
		yield put(showToastAction.request(generateSuccessToast(i18n.t('place.was.successfuly.updated') as string)));
	} catch (e) {
	}

	yield put(placeActions.setState({loading: false}));
}

function* removeWorker({payload}: TDataWrapper<TRemovePlacePayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id} = payload;

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.remove], {token, _id});
		yield put(placeActions.set({place: null}));
	} catch (e) {
	}

	yield put(placeActions.setState({loading: false, response: EPlacesResponse.REMOVE}));
}

function* generateQrcodeWorker({payload}: TDataWrapper<TGenerateQrcodePayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id} = payload;

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.generateQrcode], {token, _id});
		if (response.data.status == 'ok')
			yield put(placeActions.setState({loading: false, response: EPlacesResponse.QR_CODE_GENERATED}));
	} catch (e) {
		yield put(placeActions.setState({loading: false, response: EPlacesResponse.ERROR}));
	}
}

function* removePhotoWorker({payload}: TDataWrapper<TRemovePhotoPayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id, photo_id} = payload;
	try {
		yield call([ApiPlaceService, ApiPlaceService.removePhoto], {token, _id, photo_id});
	} catch (e) {
	}

	yield put(placeActions.setState({loading: false}));
}

function* removeDocumentWorker({payload}: TDataWrapper<TRemoveDocumentPayload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();
	const {_id, document_id} = payload;
	try {
		yield call([ApiPlaceService, ApiPlaceService.removeDocument], {token, _id, document_id});
	} catch (e) {
	}

	yield put(placeActions.setState({loading: false}));
}

function* getTransactionsWorker({payload}: TDataWrapper<TGetTransactionsPyaload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.getTransactions], {token, ...payload});
		if (response.data) {
			yield put(placeActions.setTransactions({data: response.data.transactions, total: response.data.total}));
		}
	} catch (e) {
	}
	yield put(placeActions.setState({loading: false}));
}

function* getVisitedWorker({payload}: TDataWrapper<TGetTransactionsPyaload>) {
	yield put(placeActions.setState({loading: true}));
	const token: string = yield getAccessToken();

	try {
		const response: TResponse = yield call([ApiPlaceService, ApiPlaceService.getVisited], {token, ...payload});
		if (response.data) {
			console.log('response', response.data.visited);

			yield put(placeActions.setVisited({data: response.data.visited, total: response.data.total}));
		}
	} catch (e) {
	}
	yield put(placeActions.setState({loading: false}));
}

export function* establishmentWatcher() {
	yield takeLatest(placeActions.getList, getListWorker);
	yield takeLatest(placeActions.getCount, countWorker);
	yield takeLatest(placeActions.create, createWorker);
	yield takeLatest(placeActions.get, getWorker);
	yield takeLatest(placeActions.edit, editWorker);
	yield takeLatest(placeActions.remove, removeWorker);
	yield takeLatest(placeActions.generateQrcode, generateQrcodeWorker);
	yield takeLatest(placeActions.removePhoto, removePhotoWorker);
	yield takeLatest(placeActions.removeDocument, removeDocumentWorker);
	yield takeLatest(placeActions.getTransactions, getTransactionsWorker);
	yield takeLatest(placeActions.getVisited, getVisitedWorker);
}
