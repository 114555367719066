import { AxiosError } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import { generateErrorToast, generateSuccessToast, i18n } from "../../common";

import { showToastAction } from "../toasts";
import { ApiInfoService } from "./api.service";
import { createInfoAction, getInfoAction, getInfoByIdAction, updateInfoAction } from "./actions";
import { getAccessToken } from "../admin";
import { PromiseReturnType } from "../types";

function* getInfoWorker({ payload }: ReturnType<typeof getInfoAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiInfoService.getInfo>> = yield call(
			[ApiInfoService, ApiInfoService.getInfo],
			{
				authorization: token,
				...payload,
			}
		);

		yield put(
			getInfoAction.success({
				information: response.data,
			})
		);
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.information") as string)));
		yield put(getInfoAction.failure(e as AxiosError));
	}
}

function* getInfoByIdWorker({payload}: { payload: { id: string } }) {
	try {
		const token: string = yield getAccessToken();

		const res: PromiseReturnType<ReturnType<typeof ApiInfoService.getInfoById>> = yield call(
			[ApiInfoService, ApiInfoService.getInfoById],
			{
				authorization: token,
				id: payload.id,
			}
		);
		yield put(getInfoByIdAction.success({data: res.data, status: 'ok'}));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.information") as string)))

		yield put(getInfoByIdAction.failure(e as AxiosError));
	}
}


function* createInfoWorker({ payload }: ReturnType<typeof createInfoAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiInfoService.createInfo>> =
			yield call([ApiInfoService, ApiInfoService.createInfo], {
				authorization: token,
				...payload,
			})

		yield put(createInfoAction.success(response.data))

		yield put(
			showToastAction.request(
				generateSuccessToast(i18n.t("information.was.created.successfully") as string),
			),
		)
	} catch (e: any) {
		yield put(
			showToastAction.request(
				generateErrorToast(
					e?.response?.data?.message ?? i18n.t('info.creating.error'),
				),
			),
		)
		yield put(getInfoAction.failure(e as AxiosError));
	}
}

function* updateInfoWorker({ payload }: ReturnType<typeof updateInfoAction>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiInfoService.updateInfo>> = yield call(
			[ApiInfoService, ApiInfoService.updateInfo],
			{
				title: payload.title,
				body: payload.body,
				_id: payload._id,
				authorization: token,
			}
		);

		yield put(showToastAction.request(generateSuccessToast(i18n.t("information.successfully.updated") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.updating.information") as string)));
	}
}

export function* infoWatcher() {
	yield takeLatest(getInfoAction.request, getInfoWorker);
	yield takeLatest(getInfoByIdAction.request, getInfoByIdWorker);
	yield takeLatest(createInfoAction.request, createInfoWorker);
	yield takeLatest(updateInfoAction, updateInfoWorker)
}