import React, { FC, useRef, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import { TToastProps } from './types'
import { hideToastAction } from '../../../store'

/*
 * @description 5 seconds of ms multiply by multiplier
 */
const AUTO_HIDE_DURATION: number = 1000 * 5

const Toast: FC<TToastProps> = ({ data: { severity, message, id } }) => {
  const dispatch = useDispatch()

  const timeout = useRef<NodeJS.Timeout | null>(null)

  const hide = useCallback(() => {
    dispatch(hideToastAction(id))
  }, [id, dispatch])

  useEffect(() => {
    timeout.current = setTimeout(hide, AUTO_HIDE_DURATION)

    return () => {
    }
  }, [id, hide])

  return (
    <Alert severity={severity} onClose={hide}>
      {message}
    </Alert>
  )
}

export default Toast
