import { AxiosResponse } from "axios";
import {HttpService, setAuthorizationHeadersProp} from "../../common";
import {PayloadAuth, TResponse} from "../types";
import { PLACE_URL } from "./config";
import {
    TAddDocumentRequestPaylaod,
    TAddPhotoRequestPaylaod,
    TCreatePlaceRequestPyaload,
    TEditPlaceRequestPayload,
    TGetPlaceCountRequestPayload,
    TGetPlaceRequestPayload,
    TGetPlacesRequestPayload,
    TRemoveDocumentRequestPayload,
    TRemovePlaceRequestPayload,
    TRemovePhotoRequestPayload,
    TGenerateQrcodeRequestPayload,
    TGetTransactionsRequestPaylaod,
    TGetVisitedRequestPaylaod, GetPlacesActionResponse, TGetPlacesPayload,
} from "./types";

export class ApiPlaceService extends HttpService {

    static getList(
      request: PayloadAuth & TGetPlacesPayload,
    ): Promise<AxiosResponse<GetPlacesActionResponse>> {
        return this.request<GetPlacesActionResponse>({
            url: PLACE_URL.get_list(
              request.page,
              request.limit,
              request.type,
              request.search
            ),
            method: 'GET',
            headers: {
                ...setAuthorizationHeadersProp(request.authorization),
            },
        })
    }

    static getCount({ token }: TGetPlaceCountRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: PLACE_URL.count,
            method: 'GET',
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static create({ token, data }: TCreatePlaceRequestPyaload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: PLACE_URL.create,
            method: 'POST',
            data,
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static get({ token, _id }: TGetPlaceRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.get}/${_id}`,
            method: 'GET',
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static edit({ token, data, _id }: TEditPlaceRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.edit}/${_id}`,
            method: 'PATCH',
            data,
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static remove({ token, _id }: TRemovePlaceRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.remove}/${_id}`,
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })

        return response;

    }


    static addPhoto({ token, _id, data }: TAddPhotoRequestPaylaod): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.addPhoto}/${_id}`,
            method: 'PUT',
            data,
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static removePhoto({ token, _id, photo_id }: TRemovePhotoRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.deletePhoto}/${_id}/${photo_id}`,
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })

        return response;

    }


    static addDocument({ token, _id, data }: TAddDocumentRequestPaylaod): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.addDocument}/${_id}`,
            method: 'PUT',
            data,
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static removeDocument({ token, _id, document_id }: TRemoveDocumentRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.deleteDocument}/${_id}/${document_id}`,
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })

        return response;

    }

    static generateQrcode({ token, _id }: TGenerateQrcodeRequestPayload): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: `${PLACE_URL.generateQrcode}/${_id}`,
            method: 'PUT',
            headers: {
                Authorization: token
            }
        })

        return response
    }

    static getTransactions({ token, id, page, limit }: TGetTransactionsRequestPaylaod): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: PLACE_URL.getTransactions(id),
            method: 'GET',
            params: {
                page,
                limit
            },
            headers: {
                Authorization: token
            }
        })

        return response
    }

    static getVisited({ token, id, page, limit }: TGetVisitedRequestPaylaod): Promise<AxiosResponse<TResponse>> {
        const response = this.request<TResponse>({
            url: PLACE_URL.getVisited(id),
            method: 'GET',
            params: {
                page,
                limit
            },
            headers: {
                Authorization: token
            }
        })

        return response
    }


}