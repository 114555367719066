import styled from "styled-components"
import { FONT, colors } from "../../common"

export const Container = styled.div`
  margin: 15px;
  padding: 10px 50px;
`

export const FormContainer = styled.form`
  width: 400px;
  margin-bottom: 50px;
`

export const Input = styled.input`
  width: 350px;
  padding: 7px;
`

export const Title = styled.h4`
  margin-top: 20px;
  
  ${FONT({weight: '700', size: '26px', color: colors.lightBlue})}
`

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const SectionBlock = styled.div``;

export const CheckboxContainer = styled.div`
  width: 200px;
`

export const CheckboxBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 160px;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100px;
`;
