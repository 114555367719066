import styled from "styled-components";
import {colors, FLEX, FONT} from "../../common";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FormContainer = styled.form`
  width: 400px;
  margin-bottom: 20px;
`

export const Div = styled.div`
  width: 31px;
  height: 26px;
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'center', justify: 'space-between' })}
`

export const Title = styled.h4`
  margin-top: 20px;
  
  ${FONT({weight: '700', size: '26px', color: colors.lightBlue})}
`

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SectionBlock = styled.div`
  margin: 20px 20px;
`;

export const CheckboxContainer = styled.div`
  width: 200px;
`

export const CheckboxBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 160px;
`

export const DeleteButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.red};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const SaveButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.green};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

