import { createReducer } from "@reduxjs/toolkit";

import {
	AdminActions,
	createAdminAction,
	getAdminsAction,
	getAdminByIdAction,
	clearAdminAction
} from "./actions";
import { TInitialState } from "./types";

const InitialState: TInitialState = {
	admins: [],
	total: 0,
	admin: null,
	newAdmin: null,
	loading: false,
	accessToken: null,
}

const reducer = createReducer<TInitialState>(InitialState, builder => {
	builder.addCase(AdminActions.set, (state, {payload}) => {
		return {...state, ...payload}
	});

	builder.addCase(getAdminsAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(getAdminsAction.success, (state, {payload}) => ({
		...state,
		admins: payload.admins,
		total: payload.total,
		loading: false,
	}));
	builder.addCase(getAdminsAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(getAdminByIdAction.request, (state) => ({
		...state,
		loading: true,
	}));
	builder.addCase(getAdminByIdAction.success, (state, {payload}) => ({
		...state,
		newAdmin: payload.data,
		loading: false,
	}));
	builder.addCase(getAdminByIdAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(createAdminAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(createAdminAction.success, (state, {payload}) => ({
		...state,
		newAdmin: payload.data,
		loading: false,
	}));
	builder.addCase(createAdminAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(clearAdminAction, (state) => ({
		...state,
		newAdmin: null,
	}));
})

export default reducer