import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import {
  clearCustomerAction,
  deleteCustomerAction,
  getCustomerByIdAction,
  updateCustomerAction
} from '../../store/customers'
import {
  Assets,
  useTypedSelector,
  useValidation,
  Divider,
  EInputTemplate,
  Input,
  getLocale,
  ScrollWindow,
  Card,
  FlexContainer, generateErrorToast
} from '../../common'
import { FullWidth, StyledButton, StyledButtonEnd, StyledPagination } from '../PlaceList/styled'
import { showToastAction } from "../../store"


import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  SaveButton,
  DeleteButton,
  StyledContainer,
  ProfileImage,
  Div,
  StyledText,
  StyledRedText,
  StyledPetText,
} from './styled'
import { TForm, TChangeManagerValidationSchema } from './types'

const paginationLimit = 10

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    firstname: null,
    lastname: null,
    phone: null,
    email: null,
  })

  const { t } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { customer } = useTypedSelector(store => store.customers)
  const { admin } = useTypedSelector( state => state.admin)

  const [transactionPage, setTransactionPage] = useState<number>(1)
  const [visitedPage, setVisitedPage] = useState<number>(1)

  const transactionTotal = 10
  const visitedTotal = 10


  // eslint-disable-next-line
  const inputHandler = useCallback(
    // @ts-ignore
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      firstname: {
        condition: form.firstname
          ? form.firstname.length
            ? form.firstname.length >= 2
            : true
          : true,
        error: t('enter.firstname'),
      },
      lastname: {
        condition: form.lastname
          ? form.lastname.length
            ? form.lastname.length >= 2
            : true
          : true,
        error: t('enter.lastname'),
      },
      phone: {
        condition: form.phone
          ? form.phone.length
            ? form.phone.length >= 6
            : true
          : true,
        error: t('enter.phone'),
      },
      email: {
        condition: form.email
          ? form.email.length
            ? form.email?.includes('@')
            : true
          : true,
        error: t('email.not.correct'),
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const onPressSaveChanges = async () => {
    if(admin?.permissions?.users?.update){
      try {
        enableValidation()
        await validation()

        const result = window.confirm(t('update.customer?'))

        if (result) {
          if (form.phone && form.firstname && form.email) {
            dispatch(
              updateCustomerAction({
                firstname: form.firstname,
                lastname: form.lastname as string,
                phone: form.phone,
                email: form.email,
                authorization: '',
                _id: id,
              }),
            )
          } else if (form.firstname && form.email) {
            dispatch(
              updateCustomerAction({
                firstname: form.firstname,
                lastname: form.lastname as string,
                email: form.email,
                authorization: '',
                _id: id,
              }),
            )
          } else if (form.phone) {
            dispatch(
              updateCustomerAction({
                phone: form.phone,
                authorization: '',
                _id: id,

              }),
            )
          } else if (form.firstname) {
            dispatch(
              updateCustomerAction({
                firstname: form.firstname,
                authorization: '',
                _id: id,
              }),
            )

          }
          else if (form.lastname) {
            dispatch(
              updateCustomerAction({
                lastname: form.lastname,
                authorization: '',
                _id: id,
              }),
            )

          } else if (form.email) {
            dispatch(
              updateCustomerAction({
                email: form.email,
                authorization: '',
                _id: id,
              }),
            )
          }
        }

        disableValidation()
      } catch (error) {
      }
    }else {
      dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))
    }

  }

  const onPressDeleteCustomer = async () => {
    if(admin?.permissions?.users?.delete){
      try {
        const result = window.confirm(t('remove.customer?'))

        if (result) {
          dispatch(
            deleteCustomerAction({
              authorization: '',
              _id: id,
            }),
          )

          navigate(-1)
        }
      } catch (error) {
      }
    } else {
      dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))
    }

  }

  const Events = {

    chooseVisitedPage: (page: number) => {
      localStorage.setItem(
        'customerVisitedPage',
        JSON.stringify({
          page: page === 0 ? 1 : page,
        }),
      )

      // dispatch(
      //     getClinicsAction.request({
      //         page: page === 0 ? 1 : page,
      //         limit: paginationLimit,
      //         search,
      //         sortBy: sortName,
      //     }),
      // )

      setVisitedPage(page === 0 ? 1 : page)
    },
    chooseTransactionPage: (page: number) => {
      localStorage.setItem(
        'customerTransactionPage',
        JSON.stringify({
          page: page === 0 ? 1 : page,
        }),
      )

      // dispatch(
      //     getClinicsAction.request({
      //         page: page === 0 ? 1 : page,
      //         limit: paginationLimit,
      //         search,
      //         sortBy: sortName,
      //     }),
      // )

      setTransactionPage(page === 0 ? 1 : page)
    },
  }

  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getCustomerByIdAction.request({ id: id as string }))
    // dispatch(getCustomerEventsAction.request({ id: id as string }))

    return () => {
      dispatch(clearCustomerAction())
    }
  }, [id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      firstname: customer?.firstname,
      lastname: customer?.lastname,
      phone: customer?.phone,
      email: customer?.email,
    })
  }, [customer])

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <div onClick={handleBack}>
            <Image src={Assets.LEFT_ARROW_ICON} />
          </div>
        ) : (
          ''
        )}

        <Title>{t('profile')}</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      {customer?.avatar ? (
        <ProfileImage src={customer?.avatar} />
      ) : (
        <ProfileImage
          src={
            'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png'
          }
        />
      )}

      <Divider height={10} />

      <StyledData>
        <>
          <StyledEntity>
            <Input
              label={t('firstname')}
              name="firstname"
              value={form.firstname ?? ''}
              placeholder={t('enter.firstname')}
              template={EInputTemplate.NAME}
              error={schema.firstname.error || ''}
              onChange={v => inputHandler(v)}
              isValid={!isEnabled || validationSchema.firstname}
            />
          </StyledEntity>

          <Divider width={20} />

          <StyledEntity>
            <Input
              label={t('lastname')}
              name="lastname"
              value={form.lastname ?? ''}
              placeholder={t('enter.lastname')}
              template={EInputTemplate.NAME}
              error={schema.lastname.error || ''}
              onChange={v => inputHandler(v)}
              isValid={!isEnabled || validationSchema.lastname}
            />
          </StyledEntity>

          <Divider width={20} />

          <StyledEntity>
            <Input
              label={t('phone')}
              name="phone"
              value={form.phone ?? ''}
              placeholder="+(code)999999999"
              template={EInputTemplate.NAME}
              error={schema.phone.error || ''}
              onChange={v => inputHandler(v)}
              isValid={!isEnabled || validationSchema.phone}
            />
          </StyledEntity>

          <StyledEntity>
            <Input
              label={t("email")}
              name="email"
              value={form.email ?? ''}
              placeholder={t('enter.email')}
              error={schema.email.error || ''}
              template={EInputTemplate.DEFAULT}
              onChange={v => inputHandler(v)}
              isValid={!isEnabled || validationSchema.email}
            />
          </StyledEntity>

          <Divider height={10} />
          <StyledText>{t('transaction')}</StyledText>
          <Divider height={10} />

          {!!customer?.transactions ? (
            <FlexContainer direction="column" width='100%' align='center' justify='center' gap='30px'>

              <ScrollWindow>
                {customer?.transactions.map((transaction, idx) => {
                  return (

                    <React.Fragment key={idx}>
                      <Card key={transaction._id}>
                        {/* 
                      <StyledPetText>
                        {`${t('client')} ${transaction.user}`}
                      </StyledPetText> */}
                        {/*<StyledPetText>{`${transaction.place.title[getLocale()]}`}</StyledPetText>*/}
                        {/*<Divider height={7} />*/}
                        <StyledPetText>
                          {t('date')} {moment(transaction.updatedAt).format('HH:mm DD.MM.YYYY')}
                        </StyledPetText>
                        <Divider height={7} />
                        <StyledPetText>{`${t('total.price')}: ${transaction.originalPrice}`}</StyledPetText>
                        <Divider height={7} />
                        <StyledPetText>{` ${t('discount')} ${transaction.discount}%`}</StyledPetText>
                        <Divider height={7} />
                        <StyledPetText>{`${t('price')}: ${transaction.price}`}</StyledPetText>
                        <Divider height={7} />
                      </Card>
                    </React.Fragment>
                  )
                })}
              </ScrollWindow>
              <FullWidth>
                <StyledPagination>
                  {transactionPage === 1 ? (
                    <StyledButtonEnd>{t('back')}</StyledButtonEnd>
                  ) : (
                    <StyledButton onClick={() => Events.chooseTransactionPage(transactionPage - 1)}>
                      {t('back')}
                    </StyledButton>
                  )}

                  <div>
                    {transactionPage}/
                    {!transactionTotal
                      ? 1
                      : Math.ceil(transactionTotal / paginationLimit) === 0
                        ? 1
                        : Math.ceil(transactionTotal / paginationLimit)}
                  </div>

                  {transactionPage === (!transactionTotal ? 1 : Math.ceil(transactionTotal / paginationLimit)) ? (
                    <StyledButtonEnd>{t('next')}</StyledButtonEnd>
                  ) : (
                    <StyledButton onClick={() => Events.chooseVisitedPage(transactionPage + 1)}>
                      {t('next')}
                    </StyledButton>
                  )}
                </StyledPagination>
                <Divider height={10} />
              </FullWidth>
            </FlexContainer>
          ) : (
            <StyledRedText>{t('no.transaction')}</StyledRedText>
          )}

          <Divider height={10} />
          <StyledText>{t('places')}</StyledText>
          <Divider height={10} />
          {!!customer?.visited ? (
            <FlexContainer direction="column" width='100%' align='center' justify='center' gap='30px'>

              <ScrollWindow>
                {customer?.visited.map((visited, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Card key={visited._id}>

                        <StyledPetText>
                          {`${visited.title[getLocale()]}`}
                        </StyledPetText>
                        <Divider height={7} />
                        <StyledPetText>
                          {t('date')} {moment(visited.createdAt).format('HH:mm DD.MM.YYYY')}
                        </StyledPetText>
                        <Divider height={7} />
                         <StyledPetText>{` ${t('discount')} ${visited.discount}%`}</StyledPetText>
                        <Divider height={7} />

                      </Card>
                    </React.Fragment>
                  )
                })}
              </ScrollWindow>
              <FullWidth>
                <StyledPagination>
                  {visitedPage === 1 ? (
                    <StyledButtonEnd>{t('back')}</StyledButtonEnd>
                  ) : (
                    <StyledButton onClick={() => Events.chooseVisitedPage(visitedPage - 1)}>
                      {t('back')}
                    </StyledButton>
                  )}

                  <div>
                    {visitedPage}/
                    {!visitedTotal
                      ? 1
                      : Math.ceil(visitedTotal / paginationLimit) === 0
                        ? 1
                        : Math.ceil(visitedTotal / paginationLimit)}
                  </div>

                  {visitedPage === (!visitedTotal ? 1 : Math.ceil(visitedTotal / paginationLimit)) ? (
                    <StyledButtonEnd>{t('next')}</StyledButtonEnd>
                  ) : (
                    <StyledButton onClick={() => Events.chooseVisitedPage(visitedPage + 1)}>
                      {t('next')}
                    </StyledButton>
                  )}
                </StyledPagination>
                <Divider height={10} />
              </FullWidth>
            </FlexContainer>
          ) : (
            <StyledRedText>{t('no.visited.places')}</StyledRedText>
          )}

          <Divider height={10} />

          <StyledContainer>
            <DeleteButton onClick={onPressDeleteCustomer}>{t('remove')}</DeleteButton>
            <SaveButton onClick={onPressSaveChanges}>{t('save')}</SaveButton>
          </StyledContainer>

          <Divider height={10} />
        </>
      </StyledData>
    </Container>
  )
}

export default Profile
