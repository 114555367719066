import styled from 'styled-components'
import { colors, FLEX, FONT } from '../../styles'

export const StyledInputWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
  max-width: 100%;
  width: 100%;

  padding-bottom: 10px;
`

export const Label = styled.span`
  width: 100%;

  ${FONT({
  size: '14px',
  weight: '700',
  align: 'left',
  color: colors.lightBlue,
})}
`

export const InputWrapper = styled.div`
  width: 100%;

  position: relative;
`

export const NameInputWrapper = styled.div`
  width: 100%;

  position: relative;
`

export const StyledInput = styled.input`
  width: 100%;

  margin: 1;
  padding: 10px 14px;

  padding-right: 40px;

  outline: none;
  border: 2px solid ${colors.gray};

  background: ${colors.gray};

  ${FONT({
  size: '14px',
  weight: '700',
  color: colors.lightBlue,
})}

  transition: 0.2s;

  &:focus {
    border: 2px solid ${colors.green};
    background: ${colors.white};
  }

  &::placeholder {
    ${FONT({
  size: '14px',
  weight: '700',
  color: '',
})}
  }
`

export const Error = styled.span`
  width: 100%;
  height: 5px;

  ${FONT({
  size: '12px',
  weight: '700',
  align: 'right',
  color: colors.red,
})}
`

export const Button = styled.button``

export const InputImage = styled.img`
  position: absolute;

  width: 20px;
  height: 20px;

  right: 10px;
  bottom: 10px;
`
