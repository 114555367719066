import { configureStore } from "@reduxjs/toolkit"
import rootReducer from './reducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./saga";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistedReducer = persistReducer(
    {
        key: 'dev.wtg',
        storage,
        whitelist: ['admin']
    },
    rootReducer
)


const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]
const store = configureStore({
    reducer: persistedReducer,
    middleware
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store