import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "rc-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Navigate } from "react-router-dom";

import { Assets, Divider, EInputTemplate, Input, useTypedSelector } from "../../common";
import { getAdminsAction } from "../../store";
import Routes from "../../router/routes";

import {
	Container,
	Header,
	Title,
	StyledPagination,
	StyledEntity,
	TableWrapper,
	StyledRow,
	StyledHeader,
	StyledTable,
	FullWidth,
	Image,
	StyledButtonEnd,
	StyledButton,
	StyledLink,
} from "./styled";


const Administrators = () => {
	const [typedAdministrators, setTypedAdministrators] = useState<any>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [paginationLimit, setPaginationLimit] = useState<number>(20)
	const [searchPage, setSearchPage] = useState<number>(1)
	const [search, setSearch] = useState<string>('')

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { admins, admin, total } = useTypedSelector(state => state.admin);

	// eslint-disable-next-line
	useEffect(() => {
		const localValues = localStorage.getItem('administrators') ?? ''
		const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
		// eslint-disable-next-line
		!!localValues
			? (setCurrentPage(parseLocalValues.page),
				setSearchPage(parseLocalValues.page),
				setSearch(parseLocalValues.search),
				dispatch(
					getAdminsAction.request({
						page: parseLocalValues.page,
						limit: paginationLimit,
						search: parseLocalValues.search,
					}),
				))
			: dispatch(
				getAdminsAction.request({
					page: currentPage,
					limit: paginationLimit,
					search,
				}),
			)
	}, [])

	const columns = [
		{
			title: t('email/phone'),
			dataIndex: 'entry',
			key: 'entry',
		},
		{
			title: t('createdAt'),
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: t('profile'),
			dataIndex: 'profile',
			key: 'profile',
		},
	]

	const choosePage = (page: number) => {
		dispatch(
			getAdminsAction.request({
				page: page === 0 ? 1 : page,
				limit: paginationLimit,
				search,
			}),
		)

		localStorage.setItem(
			'administrators',
			JSON.stringify({
				page: page === 0 ? 1 : page,
				search,
			}),
		)

		setCurrentPage(page === 0 ? 1 : page)
	}

	const enterKeyDown = (e: any) => {
		e?.code === 'Enter' &&
		choosePage(
			searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit))
				? searchPage !== currentPage
					? searchPage
					: 1
				: 1,
		)
	}

	const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
		setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
	}

	const putTitle = (e: any) => {
		setSearch(e.target.value)
	}

	const data = () => {
		return admins?.map((a:any) => {
			return {
				entry: !!a?.email
					? a?.email
					 	: '-',
				createdAt: moment(a?.createdAt).isValid()
					? moment(a?.createdAt).format('YYYY-MM-DD HH:mm')
					: '-',
				key: a?._id,
				profile: (
					<StyledLink
						to={{
							pathname: `/admin/profile/${a?._id}`,
						}}
					>
						{t('profile')}
					</StyledLink>
				),
			}
		})
	}

	useEffect(() => {
		setTypedAdministrators(data())
	}, [admins])

	if(!admin?.permissions.createAdministrator.read) {
		return <Navigate to={Routes.notAvailable.path}/>
	}

	return (
		<Container>
			<div>
				<Header>
					<Title>{t('administrators')} ({total ?? 0})</Title>

					<StyledPagination>
						<StyledEntity onKeyDown={enterKeyDown}>
							<Input
								label={t('search')}
								name="search"
								value={search}
								placeholder={t('name')}
								template={EInputTemplate.NAME}
								onChange={putTitle}
							/>
						</StyledEntity>
						<Divider width={10}/>
						<Image
							src={Assets.SEARCH_ICON}
							onClick={() =>
								choosePage(
									searchPage <=
									(total === 0 ? 1 : Math.ceil(total / paginationLimit))
										? searchPage
										: 1,
								)
							}
						/>
					</StyledPagination>

					<StyledPagination>
						<StyledEntity onKeyDown={enterKeyDown}>
							<Input
								label={t('page')}
								name="searchPage"
								value={searchPage.toString()}
								placeholder={t('page')}
								template={EInputTemplate.NAME}
								onChange={putSearchPage}
							/>
						</StyledEntity>
						<Divider width={10}/>
						<Image
							src={Assets.SEARCH_ICON}
							onClick={() =>
								choosePage(
									searchPage <=
									(total === 0 ? 1 : Math.ceil(total / paginationLimit))
										? searchPage
										: 1,
								)
							}
						/>
					</StyledPagination>
				</Header>

				<Divider height={30}/>

				<TableWrapper>
					<Table
						columns={columns}
						data={typedAdministrators}
						scroll={{y: 650}}
						components={{
							body: {
								row: StyledRow,
							},
							header: {
								cell: StyledHeader,
							},
							table: StyledTable,
						}}
					/>
				</TableWrapper>

				<Divider height={20}/>
			</div>

			<FullWidth>
				<StyledPagination>
					{currentPage === 1 ? (
						<StyledButtonEnd>{t('back')}</StyledButtonEnd>
					) : (
						<StyledButton onClick={() => choosePage(currentPage - 1)}>
							{t('back')}
						</StyledButton>
					)}

					<div>
						{currentPage}/
						{!total
							? 1
							: Math.ceil(total / paginationLimit) === 0
								? 1
								: Math.ceil(total / paginationLimit)}
					</div>

					{currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
						<StyledButtonEnd>{t('next')}</StyledButtonEnd>
					) : (
						<StyledButton onClick={() => choosePage(currentPage + 1)}>
							{t('next')}
						</StyledButton>
					)}
				</StyledPagination>
				<Divider height={10}/>
			</FullWidth>
		</Container>
	)
}

export default Administrators