import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import {
  api,
  Assets,
  Button,
  Card,
  Divider,
  EInputTemplate,
  ELocales,
  EmptyTranslateForm,
  FlexContainer,
  generateErrorToast,
  Input,
  ScrollWindow, TLangForm,
  useTypedSelector,
  useValidation,
} from '../../common';
import 'react-dropdown/style.css';
import ruLocale from 'date-fns/locale/ru';

import {
  Container,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  ProfileImage,
  Div,
  StyledInputWrapper,
  Label,
  StyledTextArea,
  StyledSaveButton,
  StyledContainer,
  DeleteButton,
  SaveButton,
  BottomDiv,
  StyledLinkDiv,
  StyledDropDown,
  StyledUrlImage,
  PhotoImage,
  StyledDiv,
  StyledText,
  StyledRedText,
  FullWidth,
  StyledButtonEnd,
  StyledButton,
} from './styled';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { TForm, TPhoto, TLoginValidationSchema, TGallery } from './types';

import 'bootstrap/dist/css/bootstrap.min.css';

import { ShowPhotos, Map, SocialNets, languages } from './components';

import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import {
  EPlacesResponse,
  placeActions,
  getRootPlaceSelector,
  showToastAction,
  TPlaceSendData,
  TPlaceType,
} from '../../store';
import moment from 'moment';
import { StyledPetText } from '../Profile/styled';
import { StyledPagination } from '../PlaceList/styled';
import {countryList} from "../../common/country/country";

const paginationLimit = 20;

const PlaceInfo: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { place, response, transactions, visited } = useTypedSelector(getRootPlaceSelector);
  const { type, id } = useParams();

  const [formNoValid, setFormNoValid] = useState<TPhoto>({
    photo: null,
  });
  const [language, setLanguage] = useState<string>(ELocales.default);

  const [country, setCountry] = useState<TLangForm>(EmptyTranslateForm);

  const [placePhotos, setPlacePhotos] = useState<TGallery>([]);
  const [newPlacePhotos, setNewPlacePhotos] = useState<File[]>([]);
  const [deletedPlacePhotos, setDeletedPlacePhotos] = useState<TGallery>([]);

  const [placeDocuments, setPlaceDocuments] = useState<TGallery>([]);
  const [newPlaceDocuments, setNewPlaceDocuments] = useState<File[]>([]);
  const [deletePlaceDocuments, setDeletePlaceDocuments] = useState<TGallery>([]);

  const [transactionPage, setTransactionPage] = useState<number>(1);
  const [visitedPage, setVisitedPage] = useState<number>(1);

  const transactionTotal = useMemo(() => {
    return transactions.total;
  }, [transactions.total]);

  const visitedTotal = useMemo(() => {
    return visited.total;
  }, [visited.total]);

  const [form, setForm] = useState<TForm>({
    description: EmptyTranslateForm,
    phone: '',
    title: EmptyTranslateForm,
    address: EmptyTranslateForm,
    country: EmptyTranslateForm,
    coordinates: {
      lat: 1,
      lng: 1,
    },
    site: '',
    email: '',
    social: {
      viber: '',
      telegram: '',
      facebook: '',
      vk: '',
      instagram: '',
    },
    discount: '',
  });

  //eslint-disable-next-line
  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      title: {
        condition: !!form.title.es && !!form.title.uk && !!form.title.en,
        error: t('enter.title'),
      },
      address: {
        condition: !!form.address,
        error: t('enter.address'),
      },
      discount: {
        condition: !!form.discount && form.discount !== '0',
        error: t('enter.discount')
      }
    }),
    [form]
  );

  const {
    validationSchema,
    validation,
    enableValidation,
    disableValidation,
    isEnabled,
    errors,
    isValid,
    //eslint-disable-next-line
  } = useValidation(schema);

  const changeType = (type: string) => {
    setLanguage(type);
  };

  const inputHandler = (e: any) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const inputMultiLangHandler = (e: any) => {
    setForm((prev) => ({
      ...prev,
      //@ts-ignore
      [e.target.name]: { ...prev[e.target.name], [language]: e.target.value },
    }));
  };

  const inputMultiLanguageCountryHandler = (e: any) => {
    setForm((prev) => ({
      ...prev,
      //@ts-ignore
      country: countryList.filter((el: any) => el[language] === e.target.value)[0] ?? '',
    }));
  }

  console.log(form, 'form')

  useEffect(() => {
    console.log(place?.contactInfo.country)
  }, [place])

  const Events = {
    chooseVisitedPage: (page: number) => {
      localStorage.setItem(
        'placeVisitedPage',
        JSON.stringify({
          page: page === 0 ? 1 : page,
        })
      );
      setVisitedPage(page === 0 ? 1 : page);
    },
    chooseTransactionPage: (page: number) => {
      localStorage.setItem(
        'placeTransactionPage',
        JSON.stringify({
          page: page === 0 ? 1 : page,
        })
      );

      setTransactionPage(page === 0 ? 1 : page);
    },
    handleBack: () => {
      navigate(-1);
    },
    createButtonClickHandler: async () => {
      if(!place?.permission.create) {
        try {
          const result = window.confirm(t('create.place?'));

          if (result) {
            enableValidation();
            await validation();
            Requests.createPlace();

            disableValidation();
          }
        } catch (error) {
          // @ts-ignore
          error?.map((err) => {
            dispatch(showToastAction.request(generateErrorToast(err)));
          });
        }
      } else {
        dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))));
      }
    },
    editButtonClickHandler: async () => {
      if(place?.permission?.update){
        try {
          const result = window.confirm(t('update.data?'));

          if (result) {
            enableValidation();
            await validation();
            Requests.editPlace();

            disableValidation();
          }
        } catch (error) {
          // @ts-ignore
          error?.map((err) => {
            dispatch(showToastAction.request(generateErrorToast(err)));
          });
        }
      } else {
        dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))));
      }
    },
    removeClickHandler: () => {
      if (place?.permission?.delete){
        const result = window.confirm(t('remove.place?'));
        result && Requests.removeEstablishment();
      } else {
        dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))));
      }
    },
    generateQrcodeButtonClickHandler: () => {
      Requests.generateQrcode();
      Requests.editPlace();
    },
    onRemovePhotoHandler: (photo_id: string) => {
      Requests.removePhoto(photo_id);
    },
    onRemoveDocument: (document_id: string) => {
      Requests.removeDocument(document_id);
    },
  };

    const Requests = {
    removeDocument: (document_id: string) => {
      dispatch(placeActions.removeDocument({ document_id, _id: id as string }));
    },
    removePhoto: (photo_id: string) => {
      dispatch(placeActions.removePhoto({ photo_id, _id: id as string }));
    },
    generateQrcode: () => {
      place?._id && dispatch(placeActions.generateQrcode({ _id: place._id }));
    },
    createPlace: () => {
      const data: TPlaceSendData = {
        type: type?.toUpperCase() as TPlaceType,
        title: { uk: form.title.uk, en: form.title.en, es: form.title.es },
        description: { uk: form.description.uk, en: form.description.en, es: form.description.es },
        contactInfo: {
          country: {uk: form?.country.uk, en: form?.country.en, es: form.country.es},
          phone: form.phone,
          site: form.site,
          email: form.email,
          location: {
            address: form.address,
            coords: {
              type: 'Point',
              coordinates: [form.coordinates.lng, form.coordinates.lat],
              centerPoint: [form.coordinates.lng, form.coordinates.lat],
            },
          },
          geo: {
            type: 'Point',
            coordinates: [form.coordinates.lng, form.coordinates.lat],
            centerPoint: [form.coordinates.lng, form.coordinates.lat],
          },
          social: {
            ...form.social,
          },
        },
        discount: isNaN(Number(parseFloat(form.discount)).toFixed(1) as any)
          ? 0
          : (Number(parseFloat(form.discount)).toFixed(1) as any),
      };
      dispatch(placeActions.create({ data, images: newPlacePhotos, documents: newPlaceDocuments }));
      Utils.resetStates();
    },
    clearPlace: () => {
      dispatch(placeActions.set({ place: null }));
    },
    getPlace: () => {
      dispatch(placeActions.get({ _id: id as string }));
    },
    editPlace: () => {
      const data: TPlaceSendData = {
        type: type?.toUpperCase() as TPlaceType,
        title: { uk: form.title.uk, en: form.title.en, es: form.title.es },
        description: { uk: form.description.uk, en: form.description.en, es: form.description.es },
        contactInfo: {
          country: {uk: form?.country.uk, en: form?.country.en, es: form.country.es},
          phone: form.phone,
          site: form.site,
          email: form.email,
          location: {
            address: form.address,
            coords: {
              type: 'Point',
              coordinates: [form.coordinates.lng, form.coordinates.lat],
              centerPoint: [form.coordinates.lng, form.coordinates.lat],
            },
          },
          geo: {
            type: 'Point',
            coordinates: [form.coordinates.lng, form.coordinates.lat],
            centerPoint: [form.coordinates.lng, form.coordinates.lat],
          },
          social: {
            ...form.social,
          },
        },
        discount: isNaN(Number(parseFloat(form.discount)).toFixed(1) as any)
          ? 0
          : (Number(parseFloat(form.discount)).toFixed(1) as any),
      };
      dispatch(
        placeActions.edit({
          _id: id as string,
          data,
          images: newPlacePhotos,
          removedImages: deletedPlacePhotos.map((photo) => photo.id),
          documents: newPlaceDocuments,
          removedDocuments: deletePlaceDocuments.map((document) => document.id),
        })
      );
    },
    removeEstablishment: () => {
      dispatch(placeActions.remove({ _id: id as string }));
    },
    getTransactions: () => {
      dispatch(
        placeActions.getTransactions({ page: transactionPage, limit: paginationLimit, id: place?._id as string })
      );
    },
    getVisited: () => {
      dispatch(placeActions.getVisited({ page: visitedPage, limit: paginationLimit, id: place?._id as string }));
    },
  };

  useEffect(() => {
    place?._id && Requests.getTransactions();
  }, [place, transactionPage]);

  useEffect(() => {
    place?._id && Requests.getVisited();
  }, [place, visitedPage]);

  const Utils = {
    resetStates: () => {
      setPlacePhotos([]);
      setNewPlacePhotos([]);
      setDeletedPlacePhotos([]);
      setPlaceDocuments([]);
      setNewPlaceDocuments([]);
      setDeletePlaceDocuments([]);
    },
  };

  useEffect(() => {
    place && id == 'create' && navigate(`../places/${type}/${place._id}`);

    if (id != 'create' && place) {
      setForm({
        description: place.description,
        title: place.title,
        email: place.contactInfo?.email,
        phone: place.contactInfo?.phone,
        address: place.contactInfo?.location?.address,
        site: place.contactInfo?.site,
        coordinates: {
          lng: place.contactInfo?.location?.coords?.coordinates[0],
          lat: place.contactInfo?.location?.coords?.coordinates[1],
        },
        country: place.contactInfo.country,
        social: {
          ...place.contactInfo?.social,
        },
        discount: '' + place?.discount,
      });
      setPlacePhotos(place?.meta?.photos ?? []);
      setPlaceDocuments(place?.meta?.attachments ?? []);
    }
  }, [id, navigate, place, response, type]);

  useEffect(() => {
    (response == EPlacesResponse.ERROR || EPlacesResponse.REMOVE == response) && id != 'create' && navigate(-1);
    response == EPlacesResponse.QR_CODE_GENERATED && Requests.getPlace();
  }, [response, place]);

  useEffect(() => {
    if (id !== 'create') Requests.getPlace();

    return () => {
      Requests.clearPlace();
    };
  }, [id]);

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          <StyledLinkDiv onClick={Events.handleBack}>
            <Image src={Assets.LEFT_ARROW_ICON} />
          </StyledLinkDiv>

          <Title>{t(type?.split('_').join('.') as string)}</Title>

          <Div> </Div>
        </StyledTitle>

        <Divider height={10} />

        <FlexContainer justify="center">
          {!!placePhotos.length && <ProfileImage src={`${api.withImageAPI}/place/${placePhotos[0].url}`} />}
        </FlexContainer>

        <Divider height={10} />

        <Label>{t('language')}</Label>

        <Divider height={10} />

        <StyledDropDown>
          <Dropdown
            options={languages}
            onChange={(option) => changeType(option.value)}
            value={language}
            placeholder={t('choose.language')}
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledEntity>
          <Input
            label={`${t('name')} - ` + language}
            name="title"
            value={form.title[language as keyof typeof form.title]}
            placeholder={t('enter.name')}
            template={EInputTemplate.NAME}
            error={schema?.title?.error || ''}
            onChange={(v) => inputMultiLangHandler(v)}
            isValid={!isEnabled || validationSchema.title}
          />
        </StyledEntity>

        <Divider height={10} />

        <StyledInputWrapper>
          <Label>
            {t('description')} - {language}
          </Label>

          <Divider height={10} />

          <StyledTextArea
            required
            name="description"
            placeholder={t('enter.description')}
            value={form.description[language as keyof typeof form.description]}
            onChange={inputMultiLangHandler}
          ></StyledTextArea>
        </StyledInputWrapper>

        <Divider height={20} />

        <StyledEntity>
          <Input
            label={t('email')}
            name="email"
            value={form.email}
            placeholder={t('enter.email')}
            template={EInputTemplate.NAME}
            onChange={(v) => inputHandler(v)}
          />
        </StyledEntity>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label={t('site')}
            name="site"
            value={form.site}
            placeholder={t('enter.site')}
            template={EInputTemplate.NAME}
            onChange={(v) => inputHandler(v)}
          />
        </StyledEntity>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label={t('phone')}
            name="phone"
            value={form.phone}
            placeholder={t('enter.phone')}
            template={EInputTemplate.DEFAULT}
            onChange={(v) => inputHandler(v)}
          />
        </StyledEntity>

        <Divider height={10} />

        {id !== 'create' && (
          <>
            <StyledEntity>
              <Input
                label={t('created')}
                name="created"
                value={`${moment(place?.createdAt && new Date(place.createdAt)).format('HH:mm DD.MM.YYYY')}`}
                template={EInputTemplate.DEFAULT}
                onChange={() => {}}
                disabled
              />
            </StyledEntity>
            <Divider height={10} />
            <StyledEntity>
              <Input
                label={t('changed')}
                name="edited"
                value={`${moment(place?.updatedAt && new Date(place.updatedAt)).format('HH:mm DD.MM.YYYY')}`}
                template={EInputTemplate.DEFAULT}
                onChange={() => {}}
                disabled
              />
            </StyledEntity>
            <Divider height={10} />
          </>
        )}

        <Map form={form} setForm={setForm} schema={schema} validationSchema={validationSchema} isEnabled={isEnabled} />

        <Divider height={10} />

        <SocialNets form={form} setForm={setForm} />

        <Divider height={30} />

        <Divider height={10} />

        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            id="country"
            freeSolo
            options={countryList.map((option: any) => option[language])}
            value={form.country[language as keyof typeof form.country] ?? ''}
            renderOption={(props, option) => {
              return (
                <li{...props}>
                  {option}
                </li>
              );
            }}
            renderInput={(params) => {
              return <TextField
                {...params}
                name="country"
                label="Country"
                onFocus={inputMultiLanguageCountryHandler}
                onBlur={inputMultiLanguageCountryHandler}
              />
            }}
          />
        </Stack>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label={t('discount')}
            name="discount"
            value={form.discount}
            placeholder={t('enter.discount')}
            error={schema.discount.error || ''}
            template={EInputTemplate.DEFAULT}
            onChange={(v) => inputHandler(v)}
            isValid={!isEnabled || validationSchema.discount}
          />
        </StyledEntity>

        <Divider height={30} />
        {id !== 'create' && (
          <FlexContainer gap="10px" direction="column" width="192px">
            <Label>{t('Qr-code')}</Label>
            <StyledDiv style={{ width: '192px', height: '192px' }}>
              <>
                <StyledUrlImage href={`${api.withImageAPI}/qrcode/${place?.qrcode}`} target="_blank">
                  <PhotoImage src={`${api.withImageAPI}/qrcode/${place?.qrcode}`} />
                </StyledUrlImage>
              </>
            </StyledDiv>
            <Button title={t('generate')} type="submit" onClick={Events.generateQrcodeButtonClickHandler} />
          </FlexContainer>
        )}

        <Divider height={30} />

        <ShowPhotos
          items={[...placePhotos, ...newPlacePhotos]}
          setItems={setNewPlacePhotos}
          setDeleted={setDeletedPlacePhotos}
          setFixedItems={setPlacePhotos}
          type={t('gallery')}
          onRemoveFileHandler={Events.onRemovePhotoHandler}
        />

        <Divider height={20} />

        {id !== 'create' && (
          <>
            <StyledText>{t('transaction')}</StyledText>
            <Divider height={5} />
            {!!transactions.data.length ? (
              <FlexContainer direction="column" width="100%" align="center" justify="center" gap="30px">
                <ScrollWindow>
                  {transactions.data.map((place, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <Card key={place._id}>
                          <StyledPetText>
                            {t('date')} {moment(place.updatedAt).format('HH:mm DD.MM.YYYY')}
                          </StyledPetText>
                          <Divider height={7} />
                          <StyledPetText>{`${t('price')}: ${place.price}`}</StyledPetText>
                          <Divider height={7} />
                          <StyledPetText>{` ${t('discount')} ${place.discount}%`}</StyledPetText>
                          <Divider height={7} />
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </ScrollWindow>
                <FullWidth>
                  <StyledPagination>
                    {transactionPage === 1 ? (
                      <StyledButtonEnd>{t('back')}</StyledButtonEnd>
                    ) : (
                      <StyledButton onClick={() => Events.chooseTransactionPage(transactionPage - 1)}>
                        {t('back')}
                      </StyledButton>
                    )}

                    <div>
                      {transactionPage}/
                      {!transactionTotal
                        ? 1
                        : Math.ceil(transactionTotal / paginationLimit) === 0
                        ? 1
                        : Math.ceil(transactionTotal / paginationLimit)}
                    </div>

                    {transactionPage === (!transactionTotal ? 1 : Math.ceil(transactionTotal / paginationLimit)) ? (
                      <StyledButtonEnd>{t('next')}</StyledButtonEnd>
                    ) : (
                      <StyledButton onClick={() => Events.chooseTransactionPage(transactionPage + 1)}>
                        {t('next')}
                      </StyledButton>
                    )}
                  </StyledPagination>
                  <Divider height={10} />
                </FullWidth>
              </FlexContainer>
            ) : (
              <StyledRedText>{t('no.transaction')}</StyledRedText>
            )}

            <Divider height={10} />
            <StyledText>{t('customers')}</StyledText>
            <Divider height={10} />

            {!!visited?.data.length ? (
              <FlexContainer direction="column" width="100%" align="center" justify="center" gap="30px">
                <ScrollWindow>
                  {visited?.data.map((place, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <Card key={place.uid}>
                          <StyledPetText>{`${place.lastname} ${place.firstname}`}</StyledPetText>
                          <Divider height={7} />
                          <StyledPetText>
                            {t('date')} {moment(place.createdAt).format('HH:mm DD.MM.YYYY')}
                          </StyledPetText>
                          <Divider height={7} />
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </ScrollWindow>
                <FullWidth>
                  <StyledPagination>
                    {visitedPage === 1 ? (
                      <StyledButtonEnd>{t('back')}</StyledButtonEnd>
                    ) : (
                      <StyledButton onClick={() => Events.chooseVisitedPage(visitedPage - 1)}>{t('back')}</StyledButton>
                    )}

                    <div>
                      {visitedPage}/
                      {!visitedTotal
                        ? 1
                        : Math.ceil(visitedTotal / paginationLimit) === 0
                        ? 1
                        : Math.ceil(visitedTotal / paginationLimit)}
                    </div>

                    {visitedPage === (!visitedTotal ? 1 : Math.ceil(visitedTotal / paginationLimit)) ? (
                      <StyledButtonEnd>{t('next')}</StyledButtonEnd>
                    ) : (
                      <StyledButton onClick={() => Events.chooseVisitedPage(visitedPage + 1)}>{t('next')}</StyledButton>
                    )}
                  </StyledPagination>
                  <Divider height={10} />
                </FullWidth>
              </FlexContainer>
            ) : (
              <StyledRedText>{t('no.visitors')}</StyledRedText>
            )}
          </>
        )}

        <Divider height={10} />

        {id === 'create' ? (
          <StyledSaveButton onClick={Events.createButtonClickHandler}>{t('create')}</StyledSaveButton>
        ) : (
          <StyledContainer>
            <DeleteButton onClick={Events.removeClickHandler}>{t('remove')}</DeleteButton>

            <SaveButton onClick={Events.editButtonClickHandler}>{t('save')}</SaveButton>
          </StyledContainer>
        )}

        <Divider height={10} />

        <BottomDiv />
      </LocalizationProvider>
    </Container>
  );
};

export default PlaceInfo;
