export type TBackdropProps = {
  onClick: () => void
  className?: string
  fadeIn?: EBackdropFadeIn
  zIndex?: number
  children?: React.ReactNode;
}

export type TStyledBackdropProps = Pick<TBackdropProps, 'zIndex'>

export enum EBackdropFadeIn {
  fadeIn = 'fade-in',
  fadeInDelayed = 'fade-in__delay',
}
