import { Navigate } from 'react-router-dom';

import { InfoSectionPage, LoginPage, PlaceTypesPage, SettingsPage, TestPage } from '../pages';
import { PlaceListPage } from '../pages/PlaceList';
import { PlaceInfoPage } from '../pages/PlaceInfo';
import { CustomersPage } from '../pages/Customers';
import { ProfilePage } from '../pages/Profile';
import { CreateAdministratorPage } from '../pages/CreateAdministrator';
import { AdministratorsPage } from '../pages/Administrators';
import { ProfileAdminPage } from '../pages/ProfileAdmin';
import { NotAvailablePage } from '../pages/NotAvailable';

import { TRoutes } from './types';


const Routes: TRoutes = {
  login: {
    name: 'login',
    path: '/login',
    component: LoginPage,
  },
  placeTypes: {
    name: 'placeTypes',
    path: '/places',
    component: PlaceTypesPage,
  },
  placeList: {
    name: 'placeList',
    path: '/places/:type',
    component: PlaceListPage,
  },
  placeInfo: {
    name: 'placeInfo',
    path: '/places/:type/:id',
    component: PlaceInfoPage,
  },
  information: {
    name: 'information',
    path: '/info-section',
    component: InfoSectionPage,
  },
  settings: {
    name: 'settings',
    path: '/settings',
    component: SettingsPage,
  },
  customers: {
    name: 'customers',
    path: '/customers',
    component: CustomersPage,
  },
  profile: {
    name: 'profile',
    path: '/profile/:id',
    component: ProfilePage,
  },
  profileAdmin: {
    name: 'profileAdmin',
    path: '/admin/profile/:id',
    component: ProfileAdminPage
  },
  createAdministrator: {
    name: 'createAdministrator',
    path: '/create-administrator',
    component: CreateAdministratorPage,
  },
  administrators: {
    name: 'administrators',
    path: '/administrators',
    component: AdministratorsPage,
  },
  notFounded: {
    name: 'notFounded',
    path: '*',
    component: () => <Navigate to={'/login'} />,
  },
  notFoundedAuth: {
    name: 'notFoundedAuth',
    path: '*',
    component: () => <Navigate to={'/customers'} />,
  },
  notAvailable: {
    name: 'notAvailable',
    path: '/404',
    component: NotAvailablePage,
  },
  test: {
    name: 'test',
    path: '/test',
    component: TestPage,
  },
};

export default Routes;
