export const countryList = [
	{es:"Afganistán", uk: "Авганістан", en: "Afghanistan"},
	{es:"Albania", uk: 'Албанія', en: "Albania"},
	{es:"Argelia", uk: 'Алжир', en: "Algeria"},
	{es:"Samoa Americana", uk: 'Американське Самоа', en: "American Samoa"},
	{es:"Andorra", uk: 'Андорра', en: "Andorra"},
	{es:"Angola", uk: 'Ангола', en: "Angola"},
	{es:"Angguilla", uk: 'Ангілья', en: "Anguilla"},
	{es:"Antártida", uk: 'Антарктида', en: "Antarctica"},
	{es:"Argentina", uk: 'Аргентина', en: "Argentina"},
	{es:"Armenia", uk: 'Вірменія', en: "Armenia"},
	{es:"Aruba", uk: 'Аруба', en: "Aruba"},
	{es:"Australia", uk: 'Австралія', en: "Australia"},
	{es:"Austria", uk: 'Австрія', en: "Austria"},
	{es:"Azerbaiyán", uk: 'Азербайджан', en: "Azerbaijan"},
	{es:"Antigua y Barbuda", uk: 'Антигуа і Барбуда', en: "Antigua and Barbuda"},
	{es:"Bahas (el)", uk: 'Багамські острови (the)', en: "Bahamas (the)"},
	{es:"Bahrein", uk: 'Бахрейн', en: "Bahrain"},
	{es:"Bangladesh", uk: 'Бангладеш', en: "Bangladesh"},
	{es:"Barbados", uk: 'Барбадос', en: "Barbados"},
	{es:"Bielorrusia", uk: 'Білорусь', en: "Belarus"},
	{es:"Bélgica", uk: 'Бельгія', en: "Belgium"},
	{es:"Beurish", uk: 'Беліз', en: "Belize"},
	{es:"Benin", uk: 'Бенін', en: "Benin"},
	{es:"Islas Bermudas", uk: 'Бермудські острови', en: "Islas Bermudas"},
	{es:"Bután", uk: 'Бутан', en: "Bhutan"},
	{es:"Bosnia y Herzegovina", uk: 'Боснія і Герцеговина', en: "Bosnia and Herzegovina"},
	{es:"Bolivia (estado prurinacional de)", uk: 'Болівія (Багатонаціональна Держава)', en: "Bolivia (Plurinational State of)"},
	{es:"Bonaire, Sint Eustatius y Saba", uk: 'Бонайре, Сінт-Естатіус і Саба', en: "Bonaire, Sint Eustatius and Saba"},
	{es:"Bothswana", uk: 'Ботсвана', en: "Botswana, Sint Eustatius and Saba"},
	{es:"Isla Bouvet", uk: 'Острів Буве', en: "Bouvet Island"},
	{es:"Brasil", uk: 'Бразилія', en: "Brazil"},
	{es:"Territorio del Océano Índico británico (el)", uk: 'Британська територія в Індійському океані (the)', en: "British Indian Ocean Territory (the)"},
	{es:"Brunei Darussalam", uk: 'Бруней Даруссалам', en: "Brunei Darussalam"},
	{es:"Bulgaria", uk: 'Болгарія', en: "Bulgaria"},
	{es:"Burkina Faso", uk: 'Буркіна-Фасо', en: "Burkina Faso"},
	{es:"Burundi", uk: 'Бурунді', en: "Burundi"},
	{es:"Cobo Verde", uk: 'Кабо Верде', en: "Cabo Verde"},
	{es:"Camboya", uk: 'Камбоджа', en: "Cambodia"},
	{es:"Cameron", uk: 'Камерун', en: "Cameroon"},
	{es:"Canadá", uk: 'Канада', en: "Canada"},
	{es:"Islas Caimán (la)", uk: 'Кайманові острови (the)', en: "Cayman Islands (the)"},
	{es:"República Centroafricana (la)", uk: 'Центральноафриканська Республіка (Центрально-Африканська Республіка)', en: "Central African Republic (the)"},
	{es:"Chad", uk: 'Чад', en: "Chad"},
	{es:"Chile", uk: 'Чилі', en: "Chile"},
	{es:"Isla Christmas", uk: 'Китай', en: "China"},
	{es:"Cocos (Keeling) Islas (la)", uk: 'Кокосові острови (Кілінг)"', en: "Cocos (Keeling) Islands (the)"},
	{es:"Colombia", uk: 'Колумбія', en: "Colombia"},
	{es:"Comoros (el)", uk: 'Коморські острови', en: "Comoros (the)"},
	{es:"Congo (el)", uk: 'Конго', en: "Congo (the)"},
	{es:"Islas Cook (la)", uk: 'Острови Кука', en: "Cook Islands (the)"},
	{es:"Congo (la República Democrática del)", uk: 'Конго (Демократична Республіка)', en: "Congo (the Democratic Republic of the)"},
	{es:"Costa Rica", uk: 'Коста-Ріка', en: "Costa Rica"},
	{es:"Croacia", uk: 'Хорватія', en: "Croatia"},
	{es:"Cuba", uk: 'Коста Ріка', en: "Куба"},
	{es:"Curaçao", uk: 'Кюрасао', en: "Curaçao"},
	{es:"Chipre", uk: 'Кіпр', en: "Cyprus"},
	{es:"Chequia", uk: 'Чехія', en: "Czechia"},
	{es:"Côte d'Ivorire", uk: "Кот-д Івуар", en: "Côte d'Ivoire"},
	{es:"Dinamarca", uk: "Данія", en: "Denmark"},
	{es:"Djibouti", uk: "Джібуті", en: "Djibouti"},
	{es:"Dominica", uk: "Домініка", en: "Dominica"},
	{es:"República Dominicana (la)", uk: "Домініканська республіка (the)", en: "Dominican Republic (the)"},
	{es:"Ecuador", uk: "Еквадор", en: "Ecuador"},
	{es:"Eytp", uk: "Єгипет", en: "Egypt"},
	{es:"El Salvador", uk: "Сальвадор", en: "El Salvador"},
	{es:"Guinea Ecuatorial", uk: "Екваторіальна Гвінея", en: "Equatorial Guinea"},
	{es:"Etrea", uk: "Еритрея", en: "Eritrea"},
	{es:"Estonia", uk: "Естонія", en: "Estonia"},
	{es:"Eswatini", uk: "Есватіні", en: "Eswatini"},
	{es:"Etiopía", uk: "Ефіопія", en: "Ethiopia"},
	{es:"Islas Malvinas (la) [Malvinas]", uk: "Фолклендські (Мальвінські) острови", en: "Falkland Islands (the) [Malvinas]"},
	{es:"Islas Feroe (la)", uk: "Фарерські острови", en: "Faroe Islands (the)"},
	{es:"Fiji", uk: "Фіджі", en: "Fiji"},
	{es:"Finlandia", uk: "Фінляндія", en: "Finland"},
	{es:"Francia", uk: "Франція", en: "France"},
	{es:"Guayana Francesa", uk: "Французька Гвіана", en: "French Guiana"},
	{es:"Polinesia francés", uk: "Французька Полінезія", en: "French Polynesia"},
	{es:"Territorios del sur francés (el)", uk: "Французькі південні території", en: "French Southern Territories (the)"},
	{es:"Gabol", uk: "Габон", en: "Gabon"},
	{es:"Gamia (el)", uk: "Гамбія", en: "Gambia (the)"},
	{es:"Geororgia", uk: "Грузія", en: "Georgia"},
	{es:"Gemany", uk: "Німеччина", en: "Germany"},
	{es:"Ghana", uk: "Гана", en: "Ghana"},
	{es:"Gibraltar", uk: "Гібралтар", en: "Gibraltar"},
	{es:"Grecia", uk: "Греція", en: "Greece"},
	{es:"Groenlandia", uk: "Гренландія", en: "Greenland"},
	{es:"Granada", uk: "Гренада", en: "Grenada"},
	{es:"Guadalupe", uk: "Гваделупа", en: "Guadeloupe"},
	{es:"Guam", uk: "Гуам", en: "Guam"},
	{es:"Guatemala", uk: "Гватемала", en: "Guatemala"},
	{es:"Guernsey", uk: "Гернсі", en: "Guernsey"},
	{es:"Guinea", uk: "Гвінея", en: "Guinea"},
	{es:"Guinea-Bissau", uk: "Гвінея-Бісау", en: "Guinea-Bissau"},
	{es:"Guayana", uk: "Гаяна", en: "Guyana"},
	{es:"Haití", uk: "Гаїті", en: "Haiti"},
	{es:"Heard Island y McDonald Islands", uk: "Острови Херд і Макдональд", en: "Heard Island and McDonald Islands"},
	{es:"Santa Sede (el)", uk: "Святий Престол", en: "Holy See (the)"},
	{es:"Hosturas", uk: "Гондурас", en: "Honduras"},
	{es:"Hong Kong", uk: "Гонконг", en: "Hong Kong"},
	{es:"Hungría", uk: "Угорщина", en: "Hungary"},
	{es:"Islandia", uk: "Iceland", en: "Ісландія"},
	{es:"India", uk: "Індія", en: "India"},
	{es:"Indonesia", uk: "Індонезія", en: "Indonesia"},
	{es:"Irán (República Islámica de)", uk: "Іран (Ісламська Республіка)", en: "Iran (Islamic Republic of)"},
	{es:"Irak", uk: "Ірак", en: "Iraq"},
	{es:"Irlanda", uk: "Ірландія", en: "Ireland"},
	{es:"Isla del hombre", uk: "Острів Мен", en: "Isle of Man"},
	{es:"Israel", uk: "Ізраїль", en: "Israel"},
	{es:"Italia", uk: "Італія", en: "Italy"},
	{es:"Jamaica", uk: "Ямайка", en: "Jamaica"},
	{es:"Japón", uk: "Японія", en: "Japan"},
	{es:"Jersey", uk: "Джерсі", en: "Jersey"},
	{es:"JORDÁN", uk: "Йорданія", en: "Jordan"},
	{es:"Kazajstán", uk: "Казахстан", en: "Kazakhstan"},
	{es:"Kenia", uk: "Кенія", en: "Kenya"},
	{es:"Girbati", uk: "Кірібаті", en: "Kiribati"},
	{es:"Corea (la República de)", uk: "Корея (Республіка)", en: "Korea (the Republic of)"},
	{es:"Corea (la República Popular Democrática de)", uk: "Корейська Народно-Демократична Республіка", en: "Korea (the Democratic People's Republic of)"},
	{es:"Kuwait", uk: "Кувейт", en: "Kuwait"},
	{es:"Kirguistán", uk: "Киргизстан", en: "Kyrgyzstan"},
	{es:"Lao República Democrática Popular (la)", uk: "Лаоська Народно-Демократична Республіка", en: "Lao People's Democratic Republic (the)"},
	{es:"Letonia", uk: "Латвія", en: "Latvia"},
	{es:"Líbano", uk: "Ліван", en: "Lebanon"},
	{es:"Lesoto", uk: "Лесото", en: "Lesotho"},
	{es:"Liberia", uk: "Ліберія", en: "Liberia"},
	{es:"Libia", uk: "Лівія", en: "Libya"},
	{es:"Liechtenstein", uk: "Ліхтенштейн", en: "Liechtenstein"},
	{es:"Lituania", uk: "Литва", en: "Lithuania"},
	{es:"Luxemburgo", uk: "Люксембург", en: "Luxembourg"},
	{es:"Macao", uk: "Макао", en: "Macao"},
	{es:"Madagascar", uk: "Мадагаскар", en: "Madagascar"},
	{es:"Malawi", uk: "Малаві", en: "Malawi"},
	{es:"Malasia", uk: "Малайзія", en: "Malaysia"},
	{es:"Maldivas", uk: "Мальдіви", en: "Maldives"},
	{es:"Mali", uk: "Малі", en: "Mali"},
	{es:"Malta", uk: "Мальта", en: "Malta"},
	{es:"Islas Marshall (la)", uk: "Маршаллові острови", en: "Marshall Islands (the)"},
	{es:"Martinica", uk: "Мартиніка", en: "Martinique"},
	{es:"Mauritania", uk: "Мавританія", en: "Mauritania"},
	{es:"Mauricio", uk: "Маврикій", en: "Mauritius"},
	{es:"Mayotte", uk: "Майотта", en: "Mayotte"},
	{es:"México", uk: "Мексика", en: "Mexico"},
	{es:"Micronesia (estados federados de)", uk: "Мікронезія (Федеративні Штати)", en: "Micronesia (Federated States of)"},
	{es:"Moldavia (la República de)", uk: "Молдова (Республіка)", en: "Moldova (the Republic of)"},
	{es:"Mónaco", uk: "Монако", en: "Monaco"},
	{es:"Mongolia", uk: "Монголія", en: "Mongolia"},
	{es:"Montenegro", uk: "Чорногорія", en: "Montenegro"},
	{es:"Montrat", uk: "Монсеррат", en: "Montserrat"},
	{es:"Marruecos", uk: "Марокко", en: "Morocco"},
	{es:"Mozambique", uk: "Мозамбік", en: "Mozambique"},
	{es:"Myanmar", uk: "М'янма", en: "Myanmar"},
	{es:"Namibia", uk: "Намібія", en: "Namibia"},
	{es:"Nuru", uk: "Науру", en: "Nauru"},
	{es:"Nefal", uk: "Непал", en: "Nepal"},
	{es:"Países Bajos (el)", uk: "Нідерланди", en: "Netherlands (the)"},
	{es:"Nueva Caledonia", uk: "Нова Каледонія", en: "New Caledonia"},
	{es:"Nueva Zelanda", uk: "Нова Зеландія", en: "New Zealand"},
	{es:"Nicaragua", uk: "Нікарагуа", en: "Nicaragua"},
	{es:"Níger (el)", uk: "Нігер", en: "Niger (the)"},
	{es:"Nigeria", uk: "Нігерія", en: "Nigeria"},
	{es:"Nieue", uk: "Ніуе", en: "Niue"},
	{es:"Isla Norfolk", uk: "Острів Норфолк", en: "Norfolk Island"},
	{es:"Islas Marianas del Norte (la)", uk: "Північні Маріанські острови", en: "Northern Mariana Islands (the)"},
	{es:"Noruega", uk: "Норвегія", en: "Norway"},
	{es:"Omaman", uk: "Оман", en: "Oman"},
	{es:"Passtan", uk: "Пакистан", en: "Pakistan"},
	{es:"Palau", uk: "Палау", en: "Palau"},
	{es:"Palestina, estado de", uk: "Палестина, держава", en: "Palestine, State of"},
	{es:"Pagama", uk: "Панама", en: "Panama"},
	{es:"Papúa Nueva Guinea", uk: "Папуа-Нова Гвінея", en: "Papua New Guinea"},
	{es:"Paragay", uk: "Парагвай", en: "Paraguay"},
	{es:"Perú", uk: "Перу", en: "Peru"},
	{es:"Filipinas (el)", uk: "Філіппіни", en: "Philippines (the)"},
	{es:"Pitcairn", uk: "Піткерн", en: "Pitcairn"},
	{es:"Polonia", uk: "Польща", en: "Poland"},
	{es:"Portugal", uk: "Португалія", en: "Portugal"},
	{es:"Pue Rico", uk: "Пуерто-Рико", en: "Puerto Rico"},
	{es:"Katar", uk: "Катар", en: "Qatar"},
	{es:"República del norte de Macedonia", uk: "Республіка Північна Македонія", en: "Republic of North Macedonia"},
	{es:"Rumania", uk: "Румунія", en: "Romania"},
	{es:"Federación rusa (la)", uk: "Російська Федерація", en: "Russian Federation (the)"},
	{es:"Ruanda", uk: "Руанда", en: "Rwanda"},
	{es:"Reunión", uk: "Реюньйон", en: "Réunion"},
	{es:"Sint Barthélemy", uk: "Сен-Бартельмі", en: "Saint Barthélemy"},
	{es:"Sint Helena, Ascension y Tristan son Cunha", uk: "Свята Єлена, Вознесіння і Трістан да Кунья", en: "Saint Helena, Ascension and Tristan da Cunha"},
	{es:"Sint Kitts y Nevis", uk: "Сент-Кітс і Невіс", en: "Saint Kitts and Nevis"},
	{es:"Sint Lucía", uk: "Сент-Люсія", en: "Saint Lucia"},
	{es:"Sint Martin (parte francesa)", uk: "Сен-Мартен (французька частина)", en: "Saint Martin (French part)"},
	{es:"Sint Pierre y Miquelon", uk: "Сен-П'єр і Мікелон", en: "Saint Pierre and Miquelon"},
	{es:"Sint Vincent y las Granadinas", uk: "Сент-Вінсент і Гренадини", en: "Saint Vincent and the Grenadines"},
	{es:"Samoa", uk: "Самоа", en: "Samoa"},
	{es:"San Marino", uk: "Сан-Марино", en: "San Marino"},
	{es:"Un tomo y príncipe", uk: "Сан-Томе і Принсіпі", en: "Sao Tome and Principe"},
	{es:"Arabia Saudita", uk: "Саудівська Аравія", en: "Saudi Arabia"},
	{es:"Senegal", uk: "Сенегал", en: "Senegal"},
	{es:"Servie", uk: "Сербія", en: "Serbia"},
	{es:"Seychelles", uk: "Сейшельські острови", en: "Seychelles"},
	{es:"Sierra Leona", uk: "Сьєрра-Леоне", en: "Sierra Leone"},
	{es:"Singapur", uk: "Сінгапур", en: "Singapore"},
	{es:"Sint Maarten (parte holandesa)", uk: "Сінт-Мартен (голландська частина)", en: "Sint Maarten (Dutch part)"},
	{es:"Eslovaquia", uk: "Словаччина)", en: "Slovakia"},
	{es:"Eslovenia", uk: "Словенія", en: "Slovenia"},
	{es:"Islas Salomón", uk: "Соломонові острови", en: "Solomon Islands"},
	{es:"Somalia", uk: "Сомалі", en: "Somalia"},
	{es:"Sudáfrica", uk: "Південна Африка", en: "South Africa"},
	{es:"Georgia del sur y las islas Sandwich del sur", uk: "Південна Джорджія та Південні Сандвічеві острови", en: "South Georgia and the South Sandwich Islands"},
	{es:"Sudán del Sur", uk: "Південний Судан", en: "South Sudan"},
	{es:"España", uk: "Іспанія", en: "Spain"},
	{es:"Sri Lanka", uk: "Шрі Ланка", en: "Sri Lanka"},
	{es:"Sudán (el)", uk: "Судан", en: "Sudan (the)"},
	{es:"Surer", uk: "Суринам", en: "Suriname"},
	{es:"Svalbard y Jan Mayen", uk: "Шпіцберген і Ян-Маєн", en: "Svalbard and Jan Mayen"},
	{es:"Suecia", uk: "Швеція", en: "Sweden"},
	{es:"Suiza", uk: "Швейцарія", en: "Switzerland"},
	{es:"República Árabe Siria", uk: "Сирійська Арабська Республіка", en: "Syrian Arab Republic"},
	{es:"Taiwán", uk: "Тайвань", en: "Taiwan"},
	{es:"Tijikistan", uk: "Таджикистан", en: "Tajikistan"},
	{es:"Tanzania, República Unida de", uk: "Танзанія, Об'єднана Республіка", en: "Tanzania, United Republic of"},
	{es:"Tailandia", uk: "Таїланд", en: "Thailand"},
	{es:'Timor-Leste', uk: "Тимор-Лешті", en: "Timor-Leste"},
	{es:'Para levar', uk: "Йти", en: "Togo"},
	{es:'Tolelau', uk: "Токелау", en: "Tokelau"},
	{es:'Canción', uk: "Тонга", en: "Tonga"},
	{es:'Trinidad y Tobago', uk: "Тринідад і Тобаго", en: "Trinidad and Tobago"},
	{es:'Túnez', uk: "Туніс", en: "Tunisia"},
	{es:'Turkey', uk: "Туреччина", en: "Turkey"},
	{es:'Turkmenistán', uk: "Туркменістан", en: "Turkmenistan"},
	{es:'Islas Turcas y Caicos (la)', uk: "Острови Теркс і Кайкос (Острови)", en: "Turks and Caicos Islands (the)"},
	{es:'Tuvalu', uk: "Тувалу", en: "Tuvalu"},
	{es:'Unada', uk: "Уганда", en: "Uganda"},
	{es:'Ucrania', uk: "Україна", en: "Ukraine"},
	{es:'Emiratos Árabes Unidos (el)', uk: "Об'єднані Арабські Емірати (the)", en: "United Arab Emirates (the)"},
	{es:'Reino Unido de Gran Bretaña e Irlanda del Norte (The)', uk: 'Сполучене Королівство Великої Британії та Північної Ірландії', en: "United Kingdom of Great Britain and Northern Ireland (the)"},
	{es:'Islas menores de los Estados Unidos (las)', uk: 'Віддалені малі острови США', en: "United States Minor Outlying Islands (the)"},
	{es:'Estados Unidos de América (el)', uk: 'Сполучені Штати Америки (the)', en: "United States of America (the)"},
	{es:'Uruguay', uk: 'Уругвай', en: "Uruguay"},
	{es:'Uzbekistán', uk: 'Узбекистан', en: "Uzbekistan"},
	{es:'Vanuatu', uk: 'Вануату', en: "Vanuatu"},
	{es:'Vietnam', uk: "В'єтнам", en: "Viet Nam"},
	{es:'Venezuela (República Bolídica de)', uk: 'Венесуела (Боліваріанська Республіка)', en: "Venezuela (Bolivarian Republic of)"},
	{es:'Islas Virgen (británicas)', uk: 'Віргінські острови (Британські)', en: "Virgin Islands (British)"},
	{es:'Islas Vírgenes (EE. UU.)', uk: 'Віргінські острови (США)', en: "Virgin Islands (U.S.)"},
	{es:'Wallis y Futuna', uk: 'Уолліс і Футуна', en: "Wallis and Futuna"},
	{es:'Sahara Occidental', uk: 'Західна Сахара', en: "Western Sahara"},
	{es:'Yemen', uk: 'Ємен', en: "Yemen"},
	{es:'Zambia', uk: 'Замбія', en: "Zambia"},
	{es:'Zimbabue', uk: 'Зімбабве', en: "Zimbabwe"},
	{es:'Islas Aland', uk: 'Аландські острови', en: "Aland Islands"},
]

