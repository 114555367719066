import { all } from 'redux-saga/effects'
import { adminWatcher } from './admin'
import { customerWatcher } from './customers'
import { establishmentWatcher } from './place'
import { toastsSaga } from './toasts'
import { infoWatcher } from "./info";



function* rootSaga() {
    yield all([
        adminWatcher(),
        establishmentWatcher(),
        toastsSaga(),
        customerWatcher(),
        infoWatcher()
    ])
}

export default rootSaga
