import { useState } from 'react'
import { Assets } from '../../assets'
import {
  InputImage,
  InputWrapper,
  StyledInput,
  NameInputWrapper,
} from './styled'
import { TInputTemplates } from './types'

export const InputTemplates: TInputTemplates = {

  DEFAULT: ({ value, placeholder, onChange, name, disabled }) => (
    <StyledInput
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  ),

  PASSWORD: ({ value, placeholder, onChange, name }) => {
    const [isShown, toggle] = useState<boolean>(false)

    const toggleShown = () => {
      toggle(p => !p)
    }

    return (
      <InputWrapper>
        <StyledInput
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={isShown ? 'text' : 'password'}
        />
        <InputImage
          src={isShown ? Assets.HIDE_PASSWORD_ICON : Assets.SHOW_PASSWORD_ICON}
          onClick={toggleShown}
        />
      </InputWrapper>
    )
  },

  TITLE: ({ value, placeholder, onChange, name }) => {
    return (
      <InputWrapper>
        <StyledInput
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={'title'}
        />
      </InputWrapper>
    )
  },

  NAME: ({ value, placeholder, onChange, name, onBlur }) => {
    return (
      <NameInputWrapper>
        <StyledInput
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={'name'}
          onBlur={onBlur}
        />
      </NameInputWrapper>
    )
  },
}
