import styled from 'styled-components'
import { colors, FLEX, FONT } from '../../common'
export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background: ${colors.gray};

  ${FLEX({})}
`

export const LoginWrapper = styled.div`
  width: 470px;

  padding: 50px;

  border-radius: 3px;

  background: ${colors.white};
`

export const Title = styled.h1`
  ${FONT({
  size: '20px',
  weight: '700',
  align: 'center',
  color: colors.lightBlue,
})}
`

export const Link = styled.p`
  text-decoration: none;

  cursor: pointer;

  ${FONT({
  size: '14px',
  weight: '700',
  align: 'center',
  color: colors.lightBlue,
})}
`
