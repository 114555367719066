import React from 'react';
import { useTranslation }  from "react-i18next";

import { Container } from './styled';

const NotAvailable = () => {

	const { t } = useTranslation()

	return (
		<Container>
			{t('not.available')}
		</Container>
	);
};

export default NotAvailable;