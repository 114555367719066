import styled from "styled-components";
import { FLEX } from "../../common";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  
  font-weight: bold;
  font-size: 30px;
  color: #1F166A;

  ${FLEX({ justify: 'space-between', direction: 'column', wrap: 'nowrap' })}
`