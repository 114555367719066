import { AxiosResponse } from 'axios'
import {
  DeleteCustomerActionPayload,
  GetCustomerByIdActionResponse,
  GetCustomersActionPayload,
  GetCustomersActionResponse,
  PayloadSearchById,
  TCreateTransactionRequestPaylaod,
  UpdateCustomerActionPayload,
  // CreateManagerActionPayload,
  // UpdateManagerActionPayload,
  // ChangeManagerStatusActionPayload,
  // DeleteManagerActionPayload,
  // GetManagerWorkingHistoryActionResponse,
  // WorkingHistoryPayload,
} from './types'
import { PayloadAuth } from '../types'
import { CUSTOMER_URL } from './config'
import { setAuthorizationHeadersProp } from '../../common'
import { HttpService } from './http.service'

export class CustomerAPI extends HttpService {
  static getCustomers(
    request: PayloadAuth & GetCustomersActionPayload,
  ): Promise<AxiosResponse<GetCustomersActionResponse>> {
    return this.request<GetCustomersActionResponse>('GET_CUSTOMERS', {
      url: CUSTOMER_URL.get_customers(
        request.page,
        request.limit,
        request.search,
        request.sortBy,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateCustomer(
    request: UpdateCustomerActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('UPDATE_CUSTOMER', {
      url: `${CUSTOMER_URL.update_customer}${request._id}`,
      method: 'PATCH',
      data: {
        firstname: request.firstname,
        lastname: request.lastname,
        phone: request.phone,
        email: request.email,
      },
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getCustomersById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetCustomerByIdActionResponse>> {
    return this.request<GetCustomerByIdActionResponse>('GET_CUSTOMER_BY_ID', {
      url: CUSTOMER_URL.getCustomerById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getCustomerEvents(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetCustomerByIdActionResponse>> {
    return this.request<GetCustomerByIdActionResponse>('GET_CUSTOMER_EVENTS', {
      url: CUSTOMER_URL.getCustomerEvents(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteCustomer(
    request: DeleteCustomerActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_CUSTOMER', {
      url: `${CUSTOMER_URL.delete_customer}${request._id}`,
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createTransaction(
    { token, ...paylaod }: TCreateTransactionRequestPaylaod,
  ): Promise<AxiosResponse<GetCustomerByIdActionResponse>> {
    return this.request('CREATE_TRANSACTION', {
      url: 'https://wtg.kitg.com.ua/api/admin/transactions',
      method: 'POST',
      data: {
        ...paylaod
      },
      headers: {
        Authorization: token
      }
    })
  }

}