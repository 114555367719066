import { createActionTypes, createApiActions } from "actions-util";
import { AxiosError } from "axios";

import {
	CreateInfoActionPayload,
	GetInfoActionPayload,
	GetInfoByIdActionPayload,
	GetInfoByIdActionResponse,
	UpdateInfoActionPayload
} from "./types";
import { createAction } from "@reduxjs/toolkit";

export const getInfoByIdAction = createApiActions<
	GetInfoByIdActionPayload,
	GetInfoByIdActionResponse,
	AxiosError
	>(createActionTypes('INFO/GET_INFO_BY_ID'))

export const getInfoAction = createApiActions<
	GetInfoActionPayload,
	GetInfoByIdActionResponse,
	AxiosError
	>(createActionTypes('INFO/GET_INFO'))

export const createInfoAction = createApiActions<
	CreateInfoActionPayload,
	GetInfoByIdActionResponse,
	AxiosError
	>(createActionTypes('INFO/CREATE_INFO'))

export const updateInfoAction = createAction<UpdateInfoActionPayload, 'INFO/UPDATE_INFO'>(
	'INFO/UPDATE_INFO'
);