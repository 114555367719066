import React, {FC, useState} from "react";
import { StyledDropDown } from "./styled";
import Dropdown from 'react-dropdown'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { languages } from "./consts";
import {
    Button,
    ELocales,
    FlexContainer,
    generateErrorToast,
    getLocale,
    setLocale,
    useTypedSelector
} from "../../common";
import { showToastAction } from "../../store";
import 'react-dropdown/style.css'


const Settings: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const locale = getLocale()

    const { admin } = useTypedSelector(state => state.admin)

    const [language, setLanguage] = useState<ELocales>(locale)

    const Events = {
        changeLanguageHandler: (type: ELocales) => {
            setLanguage(type)
        },
        onSaveButtonHandler: () => {
            admin?.permissions?.settings?.update
            ?
              setLocale(language)
              // window.location.reload()
            :
              dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))

        }
    }

    return (
        <>
            <FlexContainer gap={'12px'} justify='flex-start'>
                <StyledDropDown>
                    <Dropdown
                        options={languages}
                        onChange={option => Events.changeLanguageHandler(option.value as ELocales)}
                        value={language}
                        placeholder="Виберите язык"
                    />
                </StyledDropDown>
                <Button title={t('save')} onClick={Events.onSaveButtonHandler} type='submit' />
            </FlexContainer>
        </>
    )
}


export default Settings