import React, {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { getMainAdminSelector } from '../../../store'
import { setScannedQrcodeAction } from '../../../store/customers'
// import { setScannedQrcodeAction } from '../../../store/customers'
import { api } from '../../config'
import { useTypedSelector } from '../../hooks'

import { IMessage, IWSContext, TWSContextProvider } from './types'

const WSContext = createContext<IWSContext>({
    ws: null,
    messages: [],
    lastMessage: null,
})

export default WSContext

export const WSContextProvider: FC<TWSContextProvider> = ({ children }) => {
    const [ws, setWS] = useState<WebSocket | null>(null)
    const [messages, setMesseges] = useState<IMessage[]>([])
    const [shouldConnect, toggleShouldConnect] = useState<boolean>(false)
    const [lastMessage, setLastMesseges] = useState<IMessage | null>(null)

    const dispatch = useDispatch()

    const type = 'admin'

    const { accessToken: token } = useTypedSelector(getMainAdminSelector)

    const WSEvents = {
        onOpen: () => {
            console.log('Websocket Callback: onOpen()')

            WSActions.SEND_TOKEN(token as string)
        },
        onMessage: async (e: any) => {
            try {
                const data = JSON.parse(e.data)
                console.log('Websocket Callback: onMessage()', data)
                setMesseges(prev => [...prev, data])
                setLastMesseges(data)
            } catch (e) {
                console.log('WS -> onMessage', e)
            }
        },
        onClose: async (e: any) => {
            setWS(null)
            console.log('Websocket Callback: onClose()', { e })

            setLastMesseges(null)
            setMesseges([])
        },
        onError: async (e: any) => {
            setWS(null)
            console.log('Websocket Callback: onError()', { e })
            setLastMesseges(null)
            setMesseges([])
        },
    }

    const WSActions = {
        CONNECT: () => {
            console.log('connect')
            setWS(new WebSocket(api.withWS))
            toggleShouldConnect(true)
        },
        SEND_TOKEN: (token: string) => {
            ws?.send(JSON.stringify({ data: { token, type }, event: 'message' }))
            console.log('Websocket Callback: sendToken()', { token, type })
        },
    }

    useEffect(() => {
        ; (async () => {
            console.log('message', lastMessage)

            // @ts-ignore
            if (lastMessage?.action == 'qr_scan') {
                dispatch(setScannedQrcodeAction({ scanned_qrcode: lastMessage.data }))
            }
            // Object.keys(lastMessage?.data ?? {}).map(el => {
            //     console.log(el)
            //     switch (el) {

            //         default:
            //             break
            //     }
            // })
        })()
    }, [lastMessage])

    useEffect(() => {
        if (ws) {
            ws.onopen = WSEvents.onOpen
            ws.onmessage = WSEvents.onMessage
            ws.onclose = WSEvents.onClose
            ws.onerror = WSEvents.onError
        }
        if (!ws && shouldConnect && token) WSActions.CONNECT()
    }, [ws])

    useEffect(() => {
        if (token) WSActions.CONNECT()
        else if (!token && ws) ws.close()
    }, [token])

    return (
        <WSContext.Provider
            value={{
                ws,
                messages,
                lastMessage,
            }}
        >
            {children}
        </WSContext.Provider>
    )
}
