import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import routes from './routes';

const AuthRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.customers.path} element={<routes.customers.component />} />

      <Route path={routes.profile.path} element={<routes.profile.component />} />

      <Route path={routes.profileAdmin.path} element={<routes.profileAdmin.component />} />

      <Route path={routes.createAdministrator.path} element={<routes.createAdministrator.component />} />

      <Route path={routes.administrators.path} element={<routes.administrators.component />} />

      <Route path={routes.settings.path} element={<routes.settings.component />} />

      <Route path={routes.placeTypes.path} element={<routes.placeTypes.component />} />

      <Route path={routes.information.path} element={<routes.information.component />} />

      <Route path={routes.placeList.path} element={<routes.placeList.component />} />

      <Route path={routes.placeInfo.path} element={<routes.placeInfo.component />} />

      <Route path={routes.notFoundedAuth.path} element={<routes.notFoundedAuth.component />} />

      <Route path={routes.notAvailable.path} element={<routes.notAvailable.component />} />

      <Route path={routes.test.path} element={<routes.test.component />} />
    </Routes>
  );
};

export default AuthRoutes;
