import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Assets } from '../..';
import { i18n } from '../../config';
import { ELocales } from '../../enums';
import { useTypedSelector } from '../../hooks';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { EInputTemplate, Input } from '../Input';
import { FlexContainer } from '../Styled';

import {
  Close,
  Label,
  Image,
  BlurContainer,
  Container,
  Modal
} from './styled';

export type TQrcodeModal = {
  onClick?: (object: any) => void;
  onCancel?: () => void;
  locale?: ELocales;
};

const QrcodeModal: FC<TQrcodeModal> = ({ onClick, onCancel }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { scanned_qrcode } = useTypedSelector((state) => state.customers);

  const [form, setForm] = useState({
    price: '',
  });

  const total = useMemo(
    () => parseInt(form.price) - parseInt(form.price) * ((scanned_qrcode?.scan.discount as number) / 100),
    [form, scanned_qrcode]
  );

  const Schema = {
    price: {
      condition: /^-?\d+\.?\d*$/.test(form.price) && parseInt(form.price) > 0,
      error: t('enter.valid.price'),
    },
  };

  console.log(scanned_qrcode?.scan.discount, 'scanned_qrcode')

  const Events = {
    onClickButton: () => {
      Schema.price.condition &&
        onClick &&
        onClick({
          price: total,
          user: scanned_qrcode?.user._id as string,
          place: scanned_qrcode?.scan.placeId as string,
          discount: scanned_qrcode?.scan.discount as number,
        });
    },
    onCancel: () => {
      onCancel && onCancel();
    },
    inputHandler: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm((prev) => ({ ...prev, [input.name]: input.value }));
    },
  };

  return (
    <Container>
      <Modal>
        <FlexContainer direction="column" justify="space-between" style={{ height: '100%' }}>
          <FlexContainer direction="column" gap="15px" position='relative'>
            <Close>
              <Image onClick={Events.onCancel} src={Assets.CLOSE}/>
            </Close>
            <Label>
              {t('place')}:<span>{scanned_qrcode?.scan?.place?.title[i18n.language as ELocales]}</span>
            </Label>
            <Label>
              {t('customer')}:<span>{`${scanned_qrcode?.user.firstname} ${scanned_qrcode?.user.lastname}`}</span>
            </Label>
            <Label>
              {t('discount')}:<span>{scanned_qrcode?.scan.discount}%</span>
            </Label>
            <Input
              name="price"
              onChange={Events.inputHandler}
              value={form.price}
              template={EInputTemplate.DEFAULT}
              placeholder={t('enter.price')}
              isValid={Schema.price.condition}
              error={Schema.price.error}
            />
          </FlexContainer>

          <Label>
            {t('total')}: <span>{isNaN(total) ? 0 : total}</span>
          </Label>
          <Button type="submit" onClick={Events.onClickButton} title={t('create')} />
          <Divider height={20} />
          <Button type="submit" onClick={Events.onCancel} title={t('cancel')} />
        </FlexContainer>
      </Modal>
      <BlurContainer />
    </Container>
  );
};

export default QrcodeModal;
