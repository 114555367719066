import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ELocales } from '../enums';

// local imports
import { uk, es, en } from '../locales'
import { getLocale } from '../utils';


const locale = getLocale()

console.log(locale)


i18n.use(initReactI18next).init({
    resources: {
        en: { translations: en },
        uk: { translations: uk },
        es: { translations: es },

    },
    fallbackLng: ELocales.default,
    lng: locale,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },

});

export default i18n;