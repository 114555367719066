import { createReducer } from "@reduxjs/toolkit";
import { createInfoAction, getInfoAction, getInfoByIdAction } from "./actions";
import { TInitialState } from "./types";

const InitialState: TInitialState = {
	information: [],
	info: null,
	loading: false,
}

export const infoReducer = createReducer<TInitialState>(InitialState, builder => {
	builder.addCase(getInfoAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(getInfoAction.success, (state, {payload}) => ({
		...state,
		information: payload.information,
		loading: false,
	}));
	builder.addCase(getInfoAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(getInfoByIdAction.request, (state) => ({
		...state,
		loading: true,
	}));
	builder.addCase(getInfoByIdAction.success, (state, {payload}) => ({
		...state,
		info: payload,
		loading: false,
	}));
	builder.addCase(getInfoByIdAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));

	builder.addCase(createInfoAction.request, state => ({
		...state,
		loading: true,
	}));
	builder.addCase(createInfoAction.success, (state, {payload}) => ({
		...state,
		info: payload.data,
		loading: false,
	}));
	builder.addCase(createInfoAction.failure, (state, {payload}) => ({
		...state,
		error: payload,
		loading: false,
	}));
})