import {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useState } from 'react'
import {
  Button,
  Divider,
  EInputTemplate,
  Input,
  useValidation
} from '../../common'

import { AdminActions } from '../../store'

import { TForm, TLoginValidationSchema } from './types'
import { Container, LoginWrapper, Title } from './styled'


export const Login: FC = () => {
  const [form, setForm] = useState<TForm>({ email: '', password: '' })

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const inputHandler = useCallback(
    (e: SyntheticEvent) => {
      const input = (e.target as HTMLInputElement)
      setForm(prev => ({ ...prev, [input.name]: input.value }))
    },
    [form],
  )

  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      email: {
        condition: form.email.includes('@'),
        error: `${t('email')}`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
  } = useValidation(schema)


  const Events = {
    onPressLogin: async () => {
      try {
        enableValidation()
        await validation()
        Requests.loginUser()
        disableValidation()
      }
      catch (e) {
        console.log(`onPressLogin ${e}`)
      }
    }
  }


  const Requests = {

    loginUser: () => {
      dispatch(AdminActions.login({ email: form.email, password: form.password }))
    }
  }


  return (
    <Container>
      <LoginWrapper>
        <Title>{t('enter.admin.panel')}</Title>

        <Divider height={20} />

        <Input
          label={t('email')}
          name="email"
          value={form.email}
          placeholder={t('your.email')}
          error={schema.email.error || ''}
          template={EInputTemplate.DEFAULT}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled || validationSchema.email}
        />

        <Input
          label={t('password')}
          name="password"
          value={form.password}
          placeholder={t('your.password')}
          template={EInputTemplate.PASSWORD}
          error={''}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled}
        />

        <Divider height={10} />

        <Button title={t('enter')} onClick={Events.onPressLogin} type="submit" />

        <Divider height={20} />

        {/* <Link onClick={onPressForgotPassword}>Забыли пароль?</Link> */}
      </LoginWrapper>
    </Container>
  )
}

export default Login
