import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
	clearAdminAction,
	deleteAdminAction,
	getAdminByIdAction,
	showToastAction,
	updateAdminAction
} from "../../store";
import { Checkbox } from "../../common/components/Checkbox";
import { data } from '../../common/data/data';
import { dataType } from '../../common/data/types';
import {
	Assets,
	Divider,
	EInputTemplate,
	generateErrorToast,
	Input,
	useTypedSelector,
} from "../../common";

import {
	Container,
	SectionBlock,
	SectionContainer,
	FormContainer,
	CheckboxBlock,
	CheckboxContainer,
	Title,
	Div,
	StyledTitle,
	Image,
	StyledContainer,
	SaveButton,
	DeleteButton
} from './styled';
import { TForm } from './types';

const ProfileAdmin = () => {

		const { id } = useParams<{ id: string }>()

		const dispatch = useDispatch()
		const { t } = useTranslation()
		const navigate = useNavigate()

		const { admin, newAdmin } = useTypedSelector(state => state.admin)

		const [formData, setFormData] = useState<TForm>({
			email: null,
			permissions: {
				users: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				entertainment: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				restaurants: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				hotels: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				skiResorts: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				shops: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				settings: {
					create: false,
					read: false,
					update: false,
					delete: false
				},
				createAdministrator: {
					create: false,
					read: false,
					update: false,
					delete: false
				}
			}
		});


	useEffect(() => {
		if(newAdmin)
		setFormData({
			email: newAdmin?.email,
			permissions: newAdmin?.permissions,
		})
	}, [newAdmin])

	const inputHandler = useCallback(
		(e: SyntheticEvent) => {
			const input = (e.target as HTMLInputElement)
			setFormData(prev => ({...prev, [input.name]: input.value}))
		},
		[formData.email],
	)

		const onPressSaveChanges = async () => {
			if (admin?.permissions?.createAdministrator?.update) {
				try {
					const result = window.confirm(t('update.administrator?'))

					if (result) {
							dispatch(
								updateAdminAction({
									permissions: formData.permissions,
									authorization: '',
									_id: id,
								}),
							)
					}
				} catch (error) {
				}
			} else {
				dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))
			}
		}

		const onPressDeleteCustomer = async () => {
			if (admin?.permissions?.createAdministrator?.delete) {
				try {
					const result = window.confirm(t('remove.administrator?'))

					if (result) {
						dispatch(
							deleteAdminAction({
								authorization: '',
								_id: id,
							}),
						)

						navigate(-1)
					}
				} catch (error) {
				}
			} else {
				dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))
			}
		}

	useEffect(() => {
		dispatch(getAdminByIdAction.request({id: id as string}))

		return () => {
			dispatch(clearAdminAction())
		}
	}, [id])

	const handleBack = () => {
		navigate(-1)
	}

	return (
			<Container>
				<StyledTitle>
					{Assets.LEFT_ARROW_ICON ? (
						<div onClick={handleBack}>
							<Image src={Assets.LEFT_ARROW_ICON}/>
						</div>
					) : (
						''
					)}

					<Title>{t('profile')}</Title>

					<Div> </Div>
				</StyledTitle>

				<Divider height={10}/>

				<FormContainer>
					<Input
						label={t("email")}
						name="email"
						value={formData.email ?? ''}
						placeholder={t('enter.email')}
						template={EInputTemplate.DEFAULT}
						disabled
					/>
				</FormContainer>
				<SectionContainer>
					{
						data.map((el: dataType, idx: number) => (
							<SectionBlock key={idx}>
								<Title>{t(el.title)}</Title>
								<CheckboxContainer>
									{
										el.items.map((i: string, idx: number) => (
											<CheckboxBlock key={idx}>
												{t(i)}
												<Checkbox
													itemName={i}
													title={el.title}
													formData={formData}
													setFormData={setFormData}
												/>
											</CheckboxBlock>
										))
									}
								</CheckboxContainer>
							</SectionBlock>
						))
					}
				</SectionContainer>

				<Divider height={20}/>

				<StyledContainer>
					<DeleteButton onClick={onPressDeleteCustomer}>{t('remove')}</DeleteButton>
					<SaveButton onClick={onPressSaveChanges}>{t('save')}</SaveButton>
				</StyledContainer>
			</Container>
		);
	}
;

export default ProfileAdmin;