import React, {useEffect, useState} from 'react';

import {
	Container,
	CheckboxBlock,
	CheckStyle,
	InputCheckbox
} from './styled';

const Checkbox = ({ itemName, title, setFormData, formData }: any) => {

	const [checked, setChecked] = useState<boolean>( false);

	useEffect(() => {
		if(formData)
		setChecked(formData?.permissions[title][itemName]);
	}, [formData])

	const handleChecked = () => {
		setChecked(!checked)
		setFormData((currentState: any) => ({
			...currentState,
			permissions: {
				...currentState.permissions,
				[title]: {
					...currentState.permissions[title],
					[itemName]: !currentState.permissions[title][itemName]
				}
			}
		}))
	};

	return (
		<Container>
			<InputCheckbox type={'checkbox'}/>
			<CheckboxBlock onClick={handleChecked}>
				{checked ? <CheckStyle> </CheckStyle> : ''}
			</CheckboxBlock>
		</Container>
	);
};

export default Checkbox;