import { createAction } from "@reduxjs/toolkit";
import { createActionTypes, createApiActions } from "actions-util";
import { AxiosError } from "axios";

import { ADMIN_LOGIN, ADMIN_LOGOUT, ADMIN_SET } from "./consts";
import {
    CreateAdminActionPayload,
    GetAdminsActionPayload,
    GetAdminByIdActionPayload,
    GetAdminByIdActionResponse,
    TLoginAdminPayload,
    TSetAdminPayload, DeleteAdminActionPayload, UpdateAdminActionPayload
} from "./types";


export const AdminActions = {
    login: createAction(ADMIN_LOGIN, (payload: TLoginAdminPayload) => ({ payload })),
    set: createAction(ADMIN_SET, (payload: TSetAdminPayload) => ({ payload })),
    logout: createAction(ADMIN_LOGOUT)
}

export const getAdminByIdAction = createApiActions<
  GetAdminByIdActionPayload,
  GetAdminByIdActionResponse,
  AxiosError
  >(createActionTypes('ADMIN/GET_ADMIN_BY_ID'))

export const getAdminsAction = createApiActions<
  GetAdminsActionPayload,
  GetAdminByIdActionResponse,
  AxiosError
  >(createActionTypes('ADMIN/GET_ADMINS'))

export const createAdminAction = createApiActions<
  CreateAdminActionPayload,
  GetAdminByIdActionResponse,
  AxiosError
  >(createActionTypes('ADMIN/CREATE_ADMIN'))

export const clearAdminAction = createAction<void, 'ADMIN/CLEAR_ADMIN'>('ADMIN/CLEAR_ADMIN');

export const deleteAdminAction = createAction<DeleteAdminActionPayload, 'ADMIN/DELETE_ADMIN'>(
  'ADMIN/DELETE_ADMIN'
)

export const updateAdminAction = createAction<UpdateAdminActionPayload, 'ADMIN/UPDATE_ADMIN'>(
  'ADMIN/UPDATE_ADMIN'
)
