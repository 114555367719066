import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { QrcodeModal, useTypedSelector } from '../common';
import { LayoutComponent } from '../pages';
import { getMainAdminSelector } from '../store/admin';
import { createTransactionAction, getCustomerSelector, setScannedQrcodeAction } from '../store/customers';

import AuthRoutes from './AuthRoutes';
import NotAuthRoutes from './NotAuthRoutes';

const Router: FC = () => {
  const dispatch = useDispatch();

  const { scanned_qrcode } = useTypedSelector(getCustomerSelector);

  const { accessToken } = useTypedSelector(getMainAdminSelector);

  const Events = {
    onClick: (prop: any) => {
      dispatch(createTransactionAction({ ...prop }));
    },
    onCancel: () => {
      dispatch(setScannedQrcodeAction({ scanned_qrcode: null }));
    },
  };

  return (
    <HashRouter basename="/">
      {accessToken ? (
        <LayoutComponent>
          <AuthRoutes />
          {/* When user scan qrcode popup this modal */}
          {/* TODO  working strange. Dont see a saga*/}
          {scanned_qrcode && <QrcodeModal onClick={Events.onClick} onCancel={Events.onCancel} />}
        </LayoutComponent>
      ) : (
        <NotAuthRoutes />
      )}
    </HashRouter>
  );
};

export default Router;
