import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Table from 'rc-table'
import moment from 'moment'
import {Navigate, Route} from "react-router-dom";

import { getCustomersAction } from '../../store/customers'
import Routes from "../../router/routes";
import {
	Assets,
	useTypedSelector,
	Divider,
	Input,
	EInputTemplate
} from '../../common'

import {
	Container,
	FullWidth,
	Header,
	StyledButton,
	StyledButtonEnd,
	StyledEntity,
	StyledHeader,
	StyledLink,
	StyledPagination,
	StyledRow,
	StyledTable,
	TableWrapper,
	Title,
	Image,
	StyledSort,
	SortImage,
} from './styled'
import { TypedCustomer, TSort } from './types'

const Customers: React.FC = () => {
	const [typedCustomers, setTypedCustomers] = useState<TypedCustomer[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [paginationLimit, setPaginationLimit] = useState<number>(20)
	const [searchPage, setSearchPage] = useState<number>(1)
	const [sort, setSort] = useState<TSort>({
		createdAt: false,
	})
	const [sortName, setSortName] = useState<string>('')
	const [search, setSearch] = useState<string>('')

	const dispatch = useDispatch()
	const {t} = useTranslation()

	const { customers, total } = useTypedSelector(state => state.customers)

	const { admin } = useTypedSelector(state => state.admin)

	// eslint-disable-next-line
	useEffect(() => {
		const localValues = localStorage.getItem('customers') ?? ''
		const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
		// eslint-disable-next-line
		!!localValues
			? (setCurrentPage(parseLocalValues.page),
				setSearchPage(parseLocalValues.page),
				setSort(parseLocalValues.sort),
				setSortName(parseLocalValues.sortName),
				setSearch(parseLocalValues.search),
				dispatch(
					getCustomersAction.request({
						page: parseLocalValues.page,
						limit: paginationLimit,
						search: parseLocalValues.search,
						sortBy: parseLocalValues.sortName,
					}),
				))
			: dispatch(
				getCustomersAction.request({
					page: currentPage,
					limit: paginationLimit,
					search,
					sortBy: '',
				}),
			)
	}, [])

	const sortByParam = (name: string) => {
		setSortName(sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC')

		setSort(prev => ({...prev, [name]: !prev[name as keyof TSort]}))

		localStorage.setItem(
			'customers',
			JSON.stringify({
				page: currentPage,
				sort: {...sort, [name]: !sort[name as keyof TSort]},
				sortName: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
				search,
			}),
		)

		dispatch(
			getCustomersAction.request({
				page: currentPage === 0 ? 1 : currentPage,
				limit: paginationLimit,
				search,
				sortBy: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
			}),
		)
	}

	const columns = [
		{
			title: t('email/phone'),
			dataIndex: 'entry',
			key: 'entry',
		},
		{
			title: t('firstname'),
			dataIndex: 'firstname',
			key: 'firstname',
		},
		{
			title: t('lastname'),
			dataIndex: 'lastname',
			key: 'lastname',
		},
		{
			title: (
				<StyledSort onClick={() => sortByParam('createdAt')}>
					<div>{t('createdAt')}</div>

					<Divider width={10}/>

					<SortImage
						src={sort.createdAt ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
					/>
				</StyledSort>
			),
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: t('profile'),
			dataIndex: 'profile',
			key: 'profile',
		},
	]

	const choosePage = (page: number) => {
		dispatch(
			getCustomersAction.request({
				page: page === 0 ? 1 : page,
				limit: paginationLimit,
				search,
				sortBy: sortName,
			}),
		)

		localStorage.setItem(
			'customers',
			JSON.stringify({
				page: page === 0 ? 1 : page,
				sort,
				sortName,
				search,
			}),
		)

		setCurrentPage(page === 0 ? 1 : page)
	}

	const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
		setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
	}

	const enterKeyDown = (e: any) => {
		e?.code === 'Enter' &&
		choosePage(
			searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit))
				? searchPage !== currentPage
					? searchPage
					: 1
				: 1,
		)
	}

	const data = () => {
		return customers?.map(customer => {
			return {
				entry: !!customer?.email
					? customer?.email
					: !!customer?.phone
						? customer?.phone
						: '-',
				firstname: !!customer?.firstname ? customer?.firstname : '-',
				lastname: !!customer?.lastname ? customer?.lastname : '-',
				createdAt: moment(customer?.createdAt).isValid()
					? moment(customer?.createdAt).format('YYYY-MM-DD HH:mm')
					: '-',
				key: customer?._id,
				profile: (
					<StyledLink
						to={{
							pathname: `/profile/${customer?._id}`,
						}}
					>
						{t('profile')}
					</StyledLink>
				),
			}
		})
	}
	// eslint-disable-next-line
	useEffect(() => {
		setTypedCustomers(data())
	}, [customers])

	const putTitle = (e: any) => {
		setSearch(e.target.value)
	}


	if(!admin?.permissions.users.read) {
		return <Navigate to={Routes.notAvailable.path}/>
	}

	return (
		<Container>
			<div>
				<Header>
					<Title>{t('customers')} ({total ?? 0})</Title>

					<StyledPagination>
						<StyledEntity onKeyDown={enterKeyDown}>
							<Input
								label={t('search')}
								name="search"
								value={search}
								placeholder={t('name')}
								template={EInputTemplate.NAME}
								onChange={putTitle}
							/>
						</StyledEntity>
						<Divider width={10}/>
						<Image
							src={Assets.SEARCH_ICON}
							onClick={() =>
								choosePage(
									searchPage <=
									(total === 0 ? 1 : Math.ceil(total / paginationLimit))
										? searchPage
										: 1,
								)
							}
						/>
					</StyledPagination>

					<StyledPagination>
						<StyledEntity onKeyDown={enterKeyDown}>
							<Input
								label={t('page')}
								name="searchPage"
								value={searchPage.toString()}
								placeholder={t('page')}
								template={EInputTemplate.NAME}
								onChange={putSearchPage}
							/>
						</StyledEntity>
						<Divider width={10}/>
						<Image
							src={Assets.SEARCH_ICON}
							onClick={() =>
								choosePage(
									searchPage <=
									(total === 0 ? 1 : Math.ceil(total / paginationLimit))
										? searchPage
										: 1,
								)
							}
						/>
					</StyledPagination>
				</Header>

				<Divider height={30}/>

				<TableWrapper>
					<Table
						columns={columns}
						data={typedCustomers}
						scroll={{y: 650}}
						components={{
							body: {
								row: StyledRow,
							},
							header: {
								cell: StyledHeader,
							},
							table: StyledTable,
						}}
					/>
				</TableWrapper>

				<Divider height={20}/>
			</div>

			<FullWidth>
				<StyledPagination>
					{currentPage === 1 ? (
						<StyledButtonEnd>{t('back')}</StyledButtonEnd>
					) : (
						<StyledButton onClick={() => choosePage(currentPage - 1)}>
							{t('back')}
						</StyledButton>
					)}

					<div>
						{currentPage}/
						{!total
							? 1
							: Math.ceil(total / paginationLimit) === 0
								? 1
								: Math.ceil(total / paginationLimit)}
					</div>

					{currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
						<StyledButtonEnd>{t('next')}</StyledButtonEnd>
					) : (
						<StyledButton onClick={() => choosePage(currentPage + 1)}>
							{t('next')}
						</StyledButton>
					)}
				</StyledPagination>
				<Divider height={10}/>
			</FullWidth>
		</Container>
	)
}

export default Customers
