



export enum ETypes {
    entartainments = 'entertainment',
    hotels = 'hotel',
    restaurants = 'restaurant',
    ski_resorts = 'ski_resort',
    shops = 'shop'
}