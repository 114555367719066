import { FC } from "react";

import { Container } from "./styled";
import { TCard } from "./types";

const Card: FC<TCard> = ({ children }) => {

    return (
        <Container>
            { children }
        </Container>
    )

}

export default Card
