import { Assets, Divider, useTypedSelector } from '../../common'
import { useTranslation } from 'react-i18next'
import { TopbarWrapper, Logo, LogoIcon, LogoContainer } from './styled'

const Topbar = () => {
  const { admin: user } = useTypedSelector(state => state.admin)

  const { t } = useTranslation()

  return (
    <TopbarWrapper>
      <LogoContainer>
        {/* <LogoIcon src={Assets.PETS_LOGO_ICON} /> */}
        {/* <Divider width={10} /> */}
        <Logo>WTG</Logo>
      </LogoContainer>

      <div>{`${t('admin')}: ${user?.email ?? '-'}`}</div>
    </TopbarWrapper>
  )
}

export default Topbar
