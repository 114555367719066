import { Provider } from "react-redux";
import { AppRouter } from "./router";
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from "./store";
import { withTranslation } from "react-i18next";
import { LoaderProvider, ToastsProvider, WSContextProvider } from "./common";

function App() {
  return (
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <LoaderProvider>
          <WSContextProvider>
            <ToastsProvider>
              <AppRouter />
            </ToastsProvider>
          </WSContextProvider>
        </LoaderProvider>
      </PersistGate>
    </Provider>
  );
}

export default withTranslation()(App);
