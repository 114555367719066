import styled from "styled-components";
import { colors, FONT } from "../../common";



export const Card = styled.a`

    width:450px;
    max-width: 100%;
    height: 150px;
    background-color: ${colors.white};
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: box-shadow .5s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

`

export const Span = styled.label`
    ${FONT({ weight: '500', size: '26px' })}

`

export const CardIcon = styled.img`

    width: 90px;
    height: auto;

`

export const Info = styled.span`
    ${FONT({ size: '15px', weight: '500' })}
`