import { createApiActions, createActionTypes } from 'actions-util'

import { createAction } from '@reduxjs/toolkit'
import {
  ShowActionRequestPayload,
  HideActionPayload,
  ShowActionSuccessPayload,
} from './types'

export const showToastAction = createApiActions<
  ShowActionRequestPayload,
  ShowActionSuccessPayload,
  void
>(createActionTypes('TOASTS/SHOW_TOAST'))

export const hideToastAction = createAction<
  HideActionPayload,
  'TOASTS/HIDE_TOAST'
>('TOASTS/HIDE_TOAST')
