import styled from 'styled-components';
import { FONT, colors, FLEX } from '../../common';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TextareaContainer = styled.div`
  margin-top: 30px;
`;

export const Header = styled.div`
  width: 100%;

  padding: 25px;

  background: ${colors.white};

  ${FLEX({ justify: 'space-between' })}
`;

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`;

export const StyledButton = styled.div`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${colors.green};

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: colors.white })}
`;

export const StyledText = styled.div`
  ${FONT({ size: '16px', color: colors.lightBlue, weight: '700' })}
`;

export const StyledDropDown = styled.div`
  width: 270px;
`;

export const Title = styled.h1`
  ${FONT({ size: '18px', color: colors.lightBlue, weight: '700' })}
`;

