import { combineReducers } from "@reduxjs/toolkit";
import { AdminReducer } from "./admin";
import { customerReducer } from "./customers";
import { infoReducer } from "./info";
import { placeReducer } from "./place";
import { toastsReducer } from "./toasts";

export default combineReducers({
    toasts: toastsReducer,
    admin: AdminReducer,
    place: placeReducer,
    customers: customerReducer,
    info: infoReducer
})