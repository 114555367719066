import styled from 'styled-components'
import { TStyledButton } from './types'
import { colors, FLEX, FONT } from '../../styles'

export const StyledButton = styled.div<TStyledButton>`
  cursor: pointer;

  padding: 14px 16px;

  border-radius: 3px;

  background: ${({ type }) => (type === 'submit' ? colors.green : colors.gray)};

  ${FLEX({})}
`

export const Label = styled.span`
  ${FONT({ size: '14px', weight: '700', color: colors.white })}
`
