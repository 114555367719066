import React, { FC, useState } from 'react'
import {

  StyledAccordion,
  StyledEntity,
} from '../styled'
import { Accordion } from 'react-bootstrap'
import { EInputTemplate, Input } from '../../../common'
import { PhonesProps } from '../types'
import { useTranslation } from 'react-i18next'
import { TSocialNet } from '../../../store/place'

export const SocialNets: FC<PhonesProps> = ({ form, setForm }) => {

  const { t } = useTranslation()

  const [social, setSocial] = useState<TSocialNet>({
    viber: '',
    telegram: '',
    facebook: '',
    vk: '',
    instagram: '',
  })

  const changeSocialNetLink = (e: any) => {
    setSocial(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const onPressBlur = () => {
    setForm(prev => ({
      ...prev,
      social,
    }))
  }

  // useEffect(() => {
  //   setSocial({
  //     viber: clinic?.contactInfo?.social?.viber ?? '',
  //     telegram: clinic?.contactInfo?.social?.telegram ?? '',
  //     whatsApp: clinic?.contactInfo?.social?.whatsApp ?? '',
  //     facebook: clinic?.contactInfo?.social?.facebook ?? '',
  //     vk: clinic?.contactInfo?.social?.vk ?? '',
  //     instagram: clinic?.contactInfo?.social?.instagram ?? '',
  //   })
  // }, [clinic])

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('social.media')}</Accordion.Header>
          <Accordion.Body>
            {Object.keys(social).map(el => (
              <StyledEntity key={el}>
                <Input
                  label={el}
                  name={el}
                  value={social[el as keyof typeof social] ?? ''}
                  placeholder={`${t('enter.link.on')} ${el}`}
                  template={EInputTemplate.NAME}
                  onChange={v => changeSocialNetLink(v)}
                  onBlur={onPressBlur}
                />
              </StyledEntity>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
