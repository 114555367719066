import { createActionTypes, createApiActions } from 'actions-util';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  GetCustomersActionResponse,
  UpdateCustomerActionPayload,
  DeleteCustomerActionPayload,
  GetCustomerByIdActionResponse,
  GetCustomerByIdActionPayload,
  GetCustomersActionPayload,
  TSetScannedQrcodePayload,
  TCreateTransactionPayload,
} from './types';

export const getCustomersAction = createApiActions<GetCustomersActionPayload, GetCustomersActionResponse, AxiosError>(
  createActionTypes('CUSTOMERS/GET_CUSTOMERS')
);

export const updateCustomerAction = createAction<UpdateCustomerActionPayload, 'CUSTOMERS/UPDATE_CUSTOMER'>(
  'CUSTOMERS/UPDATE_CUSTOMER'
);

export const setScannedQrcodeAction = createAction<TSetScannedQrcodePayload, 'CUSTOMERS/SET_SCANNED_QRCODE'>(
  'CUSTOMERS/SET_SCANNED_QRCODE'
);

export const getCustomerByIdAction = createApiActions<
  GetCustomerByIdActionPayload,
  GetCustomerByIdActionResponse,
  AxiosError
>(createActionTypes('CUSTOMERS/GET_CUSTOMER_BY_ID'));

export const getCustomerEventsAction = createApiActions<
  GetCustomerByIdActionPayload,
  GetCustomerByIdActionResponse,
  AxiosError
>(createActionTypes('CUSTOMERS/GET_CUSTOMER_EVENTS'));

export const clearCustomerAction = createAction<void, 'CUSTOMERS/CLEAR_CUSTOMER'>('CUSTOMERS/CLEAR_CUSTOMER');

export const deleteCustomerAction = createAction<DeleteCustomerActionPayload, 'CUSTOMERS/DELETE_CUSTOMER'>(
  'CUSTOMERS/DELETE_CUSTOMER'
);

export const createTransactionAction = createAction(
  'CUSTOMERS/CREATE_TRANSACTION',
  (payload: TCreateTransactionPayload) => ({ payload })
);
export const transactionDone = createAction('CUSTOMERS/TRANSACTION_DONE');
