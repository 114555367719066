import { api } from "../../common";

export const CUSTOMER_URL = {
  get_customers: (
    page: number,
    limit: number,
    search: string,
    sortBy: string,
  ) =>
    `${api.withAPI}/api/admin/user?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`,
  update_customer: `${api.withAPI}/api/admin/user/`,
  delete_customer: `${api.withAPI}/api/admin/user/`,
  getCustomerById: (id: string) => `${api.withAPI}/api/admin/user/${id}`,
  getCustomerEvents: (id: string) => `${api.withAPI}/api/admin/event/by-user/${id}`,
  // createTransaction: () => `https://wtg.kitg.com.ua/api/admin/transactions`
}
