import React, {SyntheticEvent, useCallback, useMemo, useState} from "react"
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom";

import Routes from "../../router/routes";
import {
	Button,
	EInputTemplate, generateErrorToast,
	Input,
	useTypedSelector, useValidation
} from "../../common"
import { Checkbox } from "../../common/components/Checkbox";
import { createAdminAction, showToastAction } from "../../store";
import { data } from "../../common/data/data";
import { dataType } from "../../common/data/types";

import {
	Container,
	FormContainer,
	Title,
	SectionContainer,
	SectionBlock,
	CheckboxBlock,
	CheckboxContainer,
	ButtonContainer
} from "./styled"
import { TChangeManagerValidationSchema } from "./types";

const CreateAdministrator = () => {
	const { admin } = useTypedSelector(state => state.admin)

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [formData, setFormData] = useState({
		email: '',
		role: 0,
		password: '',
		permissions: {
			users: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			entertainment: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			restaurants: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			hotels: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			skiResorts: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			shops: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			settings: {
				create: false,
				read: false,
				update: false,
				delete: false
			},
			createAdministrator: {
				create: false,
				read: false,
				update: false,
				delete: false
			}
		}
	})

	const schema = useMemo<TChangeManagerValidationSchema>(
		() => ({
			email: {
				condition: formData.email
					? formData.email.length
						? formData.email?.includes('@')
						: true
					: true,
				error: t('email.not.correct'),
			},
		}),
		[formData],
	)

	const {
		validationSchema,
		disableValidation,
		validation,
		enableValidation,
		isEnabled,
		// eslint-disable-next-line
	} = useValidation(schema)

	const inputHandler = useCallback(
		(e: SyntheticEvent) => {
			const input = (e.target as HTMLInputElement)
			setFormData(prev => ({...prev, [input.name]: input.value}))
		},
		[],
	)


	const createAdministrator = async () => {
		if(admin?.permissions?.createAdministrator?.create){
			try {

				enableValidation()
				await validation()

				const result = window.confirm(t('create.administrator?'))
				if (result){
					dispatch(createAdminAction.request(
						{
							email: formData.email,
							password: formData.password,
							permissions: formData.permissions
						}))
				}
				disableValidation()
			}catch (e) {}
		} else {
			dispatch(showToastAction.request(generateErrorToast(t("you.dont.have.access"))))
		}
	}


	if (!admin?.permissions.createAdministrator.read) {
		return <Navigate to={Routes.notAvailable.path}/>
	}

	return (
		<Container>
			<FormContainer>
				<Input
					label={t('email')}
					name="email"
					value={formData.email}
					placeholder={t('your.email')}
					template={EInputTemplate.DEFAULT}
					onChange={v => inputHandler(v)}
					error={schema.email.error || ''}
					isValid={!isEnabled || validationSchema.email}
				/>
				<Input
					label={t('password')}
					name="password"
					value={formData.password}
					placeholder={t('your.password')}
					template={EInputTemplate.PASSWORD}
					onChange={v => inputHandler(v)}
				/>
			</FormContainer>
			<SectionContainer>
				{
					data.map((el: dataType, idx: number) => (
						<SectionBlock key={idx}>
							<Title>{t(el.title)}</Title>
							<CheckboxContainer>
								{
									el.items.map((i: string, idx: number) => (
										<CheckboxBlock key={idx}>
											{t(i)}
											<Checkbox
												itemName={i}
												title={el.title}
												formData={formData}
												setFormData={setFormData}
											/>
										</CheckboxBlock>
									))
								}
							</CheckboxContainer>
						</SectionBlock>
					))
				}
			</SectionContainer>
			<ButtonContainer>
				<Button onClick={createAdministrator} type={"submit"} title={'create'}/>
			</ButtonContainer>
		</Container>
	)
}

export default CreateAdministrator
