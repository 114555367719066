import { HttpService } from "../../../../../common";
import { google_maps_uri } from "./config";
import { API_KEY } from "./consts";
import { TGetTranslatedAdressRequestPayload } from "./types";



export class GoogleMapApi extends HttpService {



    static async getTranslatedAdress({ coords, language }: TGetTranslatedAdressRequestPayload) {


        const response = this.request({
            url: google_maps_uri.getAdress,
            method: 'GET',
            params: {
                language,
                latlng: `${coords.lat} ${coords.lng}`,
                key: API_KEY
            }

        })
        const adress = (await response).data.results[0]
        return adress
    }
}