import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Coord, MapProps, TAddress } from '../../types'
import {
  containerStyle,
  StyledInputWrapper,
  StyledText,
  Error,
} from '../../styled'
import Geocode from 'react-geocode'
import { Divider, ELocales, getLocale, TLangForm } from '../../../../common'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import { lib } from '../../../../common'
import { PlaceAutocomplete } from '../PlaceAutocomplete'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { API_KEY, GoogleMapApi } from './api'
import { TGetAdressPayload, TGetTranslate, TTranslate } from './types'
import { LocalizationProvider } from '@mui/lab'
import { TypeFormatFlags } from 'typescript'

// eslint-disable-next-line react/prop-types
const Map: React.FC<MapProps> = ({
  form,
  setForm,
  schema,
  validationSchema,
  isEnabled,
}) => {
  const [center, setCenter] = useState<Coord>({
    lat: 50,
    lng: 30,
  })


  const [tempAddress, setTempAddress] = useState<string>(form.address[getLocale()])

  const [loadMarker, setLoadMarker] = useState<boolean>(false)

  const { t } = useTranslation()

  Geocode.setApiKey(API_KEY as string)
  Geocode.setLanguage('ru')
  Geocode.setRegion('uk')




  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY as string,
    libraries: lib as any,
    language: getLocale()
  })


  const getCityCoordinates = (city: string) => {
    geocodeByAddress(city)
      .then(results => {
        return getLatLng(results[0])
      })
      .then(resCoordinates => {
        setForm(prev => ({
          ...prev,
          //@ts-ignore
          cityLog: { lat: resCoordinates.lat, lng: resCoordinates.lng },
        }))
      })
      .catch(error => console.error(error))
  }

  const getElement = (results: any[], field: string) => {
    for (let i = 0; i < results.length; i++) {
      if (
        !!results[i].address_components.find((el: any) =>
          el.types.includes(field),
        ).long_name
      ) {
        return results[i].address_components.find((el: any) =>
          el.types.includes(field),
        ).long_name
      }
    }
  }

  // TODO: Hardcode need to fix soon :)
  useEffect(() => {
    isLoaded && setLoadMarker(true)
  }, [isLoaded])

  const showMarkerPosition = async (coordinates: any) => {
    const { latLng } = coordinates
    const lat = latLng.lat()
    const lng = latLng.lng()

    const addresses = await Utils.getAdressTranslations({ coords: { lat, lng } })

    Geocode.fromLatLng(lat.toString(), lng.toString())
      .then(res => {

        const city = getElement(res.results, 'locality')

        const country = getElement(res.results, 'country')

        getCityCoordinates(city)

        // eslint-disable-next-line
        setForm(prev => ({
          ...prev,

          address: { ...addresses as TLangForm },
          city,
          country,
          //@ts-ignore
          coordinates: { lat, lng },
        }))
      })
      .catch(e => console.log(e))
  }

  const addressPicker = (addressObject: TAddress) => {
    if (addressObject.hasOwnProperty('name')) {
      geocodeByAddress(addressObject.name as string)
        .then(async (results) => {
          console.log('addressPicker IF', results)

          const city = getElement(results, 'locality')

          const country = getElement(results, 'country')
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()

          const addresses = await Utils.getAdressTranslations({ coords: { lat, lng } })

          getCityCoordinates(city)

          setForm(prev => ({
            ...prev,
            //@ts-ignore
            address: { ...addresses as TLangForm },
            //@ts-ignore
            city,
            country,
          }))
          return getLatLng(results[0])
        })
        .then(resCoordinates => {
          setForm(prev => ({
            ...prev,
            //@ts-ignore
            coordinates: resCoordinates,
          }))
        })
        .catch(error => console.error(error))
    } else {
      geocodeByPlaceId(addressObject.place_id as string)
        .then(results => {

          const city = getElement(results, 'locality')

          const country = getElement(results, 'country')

          getCityCoordinates(city)
          console.log('search')
          setForm(prev => ({
            ...prev,
            //@ts-ignore
            address: {
              es: results[0].formatted_address,
              en: results[0].formatted_address,
              uk: results[0].formatted_address,
            },
            //@ts-ignore
            city,
            country,
          }))
          return getLatLng(results[0])
        })
        .then(resCoordinates => {
          setForm(prev => ({
            ...prev,
            //@ts-ignore
            coordinates: resCoordinates,
          }))
        })
        .catch(error => console.error(error))
    }
  }


  const Requests = {
    getAdress: async ({ coords, language }: TGetAdressPayload) => {
      const data = await (GoogleMapApi.getTranslatedAdress({ coords, language: language }))
      return data.formatted_address as string
    }
  }

  const Utils = {
    getAdressTranslations: async ({ coords }: TGetTranslate) => {
      const translate: TTranslate = {}
      const languages = [...new Set(Object.values(ELocales))]

      for (const language of languages) {
        const address = await Requests.getAdress({ coords, language })
        translate[language] = address
      }
      return translate
    }
  }

  useEffect(() => {
    if (!!form.coordinates.lat && !!form.coordinates.lng) {
      setCenter(form.coordinates)
    }
  }, [form.coordinates])

  useEffect(() => {
    setTempAddress(form.address[getLocale()])
  }, [form.address])

  return isLoaded ? (
    <>
      <StyledInputWrapper>
        <StyledText>{t('address')}</StyledText>

        <Divider height={10} />

        <PlaceAutocomplete
          value={tempAddress}
          onPlaceSelect={place => addressPicker(place)}
          onChange={place => setTempAddress(place)}

        />
        <Divider height={5} />

        <Error>
          {isEnabled && !validationSchema.address
            ? schema.address.error || ''
            : ''}
        </Error>
      </StyledInputWrapper>
      <Divider height={10} />
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14} >
        {loadMarker && <Marker position={center} draggable onDragEnd={showMarkerPosition} />}
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}

export default React.memo(Map)
