import styled from "styled-components";
import { colors } from "../../styles";



export const Container = styled.div`

    background-color: ${colors.darkWhite};
    width: 200px;
    height: auto;
    border: 1px solid ${colors.gray};
    border-radius: 10px;
    padding: 10px;

`