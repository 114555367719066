import styled from 'styled-components'
import { colors, FLEX, FONT } from '../../common'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledData = styled.div`
  width: 100%;

  ${FLEX({ align: 'center', direction: 'column' })}
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

export const StyledFullName = styled.div`
  width: 100%;

  ${FLEX({ justify: 'space-between', wrap: 'nowrap' })}
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'flex-start', justify: 'space-between' })}
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;

  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;

  ${FLEX({})}
`

export const PetImage = styled.img`
  /* width: 100%; */
  height: 150px;

  /* border-radius: 50%; */

  margin-left: auto;
  margin-right: auto;

  ${FLEX({})}
`

export const StyledField = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
`

export const StyledEntity = styled.div`
  width: 100%;

  ${FONT({ size: '18px', color: colors.lightBlue, weight: '700' })}
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: colors.lightBlue, weight: '700' })}
`

export const Message = styled.h1`
  ${FLEX({})}

  ${FONT({ size: '24px', color: colors.lightBlue, weight: '700' })}
`

export const SaveButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.green};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const HistoryButton = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.green};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const Div = styled.div`
  width: 31px;
  height: 26px;
`

export const StyledText = styled.div`
  width: 100%;
  ${FONT({ size: '14px', color: colors.lightBlue, weight: '700' })}
`

export const StyledPetText = styled.div`
  width: 100%;
  ${FLEX({justify: 'flex-start'})}
  ${FONT({ size: '16px', color: colors.lightBlue, weight: '700' })}
`

export const StyledEventText = styled.div`
  width: 100%;
  ${FLEX({})}
  ${FONT({ size: '20px', color: colors.black, weight: '700' })}
`

export const StyledCarousel = styled.div`
  width: 100%;

  /* padding: 0 200px; */

  ${FLEX({ wrap: 'nowrap', direction: 'row', justify: 'flex-start' })}

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledRedText = styled.div`
  width: 100%;
  ${FONT({ size: '16px', color: colors.red, weight: '700' })}
`

export const DeleteButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.red};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const HideButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const StyledLink = styled(Link)`
  padding: 10px 20px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  margin-right: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })};
`

export const StyledEvent = styled.div`
  padding: 10px 20px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  min-width: 250px;
  height: 200px;

  margin-right: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })};
`
