import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Table from 'rc-table';
import { useTranslation } from 'react-i18next';

import {
  Assets,
  Divider,
  EInputTemplate,
  FlexContainer,
  getLocale,
  Input,
  useTypedSelector
} from '../../common';
import {
  placeActions,
  getRootPlaceSelector,
  TPlaceType,
} from '../../store';

import {
  Container,
  CreateButton,
  FullWidth,
  Header,
  StyledButton,
  StyledButtonEnd,
  StyledHeader,
  StyledPagination,
  StyledRow,
  Image,
  StyledTable,
  TableWrapper,
  Title,
  StyledEntity,
  StyledSortName,
  StyledLinkDiv,
  BackButtonImage,
  StyledNumberLink,
} from './styled';
import { TypedPlaces, TSort } from './types';

const Clinics: React.FC = () => {
  const { t } = useTranslation();

  const { type } = useParams();

  const { places } = useTypedSelector(getRootPlaceSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [typedPlaces, setTypedClinics] = useState<TypedPlaces[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState<number>(20);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [typePlace, setTypePlace] = useState()
  const [sort, setSort] = useState<TSort>({
    title: false,
    type: false,
  });

  const [sortName, setSortName] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const localValues = localStorage.getItem('administrators') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        setSearchPage(parseLocalValues.page),
        setSearch(parseLocalValues.search),
        dispatch(
          placeActions.getList({
            page: parseLocalValues.page,
            limit: paginationLimit,
            type: type?.toUpperCase() as TPlaceType,
            search:search
          }),
        ))
      : dispatch(
        placeActions.getList({
          page: currentPage,
          limit: paginationLimit,
          type: type?.toUpperCase() as TPlaceType,
          search:search
        }),
      )
  }, [currentPage])

  const total = useMemo(() => places.total, [places.total]);

  const sortByParam = (name: string) => {
    setSortName(sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC');

    setSort((prev) => ({ ...prev, [name]: !prev[name as keyof TSort] }));

    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: currentPage,
        sort: { ...sort, [name]: !sort[name as keyof TSort] },
        search: search,
        sortName: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
      })
    );

    Requests.getPlaceList();
  };

  const columns = [
    {
      title: (
        <StyledSortName onClick={() => sortByParam('title')}>
          <div>{t('name')}</div>

          <Divider width={10} />
        </StyledSortName>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('adress'),
      dataIndex: 'adress',
      key: 'adress',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const data = () => {
    return places.data?.map((place) => {
      return {
        title: (
          <StyledNumberLink to={{ pathname: `/places/${type}/${place?._id}` }}>
            {place.title[getLocale()]}
          </StyledNumberLink>
        ),
        adress: (
          <StyledNumberLink to={{ pathname: `/places/${type}/${place?._id}` }}>
            {place?.contactInfo?.location?.address[getLocale()]}
          </StyledNumberLink>
        ),
        email: (
          <StyledNumberLink to={{ pathname: `/places/${type}/${place?._id}` }}>
            {place.contactInfo?.email}
          </StyledNumberLink>
        ),

        key: place?._id,
      };
    });
  };

  const choosePage = (page: number) => {
    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: page === 0 ? 1 : page,
        sort: sort,
        sortName: sortName,
        search: search,
      })
    );

    Requests.getPlaceList();

    setCurrentPage(page === 0 ? 1 : page);
  };

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value);
  };

  const enterKeyDown = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <= (total == 0 ? 1 : Math.ceil(total / paginationLimit))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1
      );
  };

  const putTitle = (e: any) => {
    setSearch(e.target.value);
  };

  const Events = {
    backButtonHandler: () => {
      navigate(-1);
    },
  };

  const Requests = {
    getPlaceList: () => {
      dispatch(
        placeActions.getList({
          page: currentPage,
          limit: paginationLimit,
          type: type?.toUpperCase() as TPlaceType,
          search: search,
          // sortBy: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
        })
      );
    },
  };

  useEffect(() => {
    Requests.getPlaceList();
  }, []);

  //eslint-disable-next-line
  useEffect(() => {
    setTypedClinics(data());
  }, [places]);

  return (
    <Container>
      <FlexContainer width="100%" justify="flex-start">
        <StyledLinkDiv onClick={Events.backButtonHandler}>
          <BackButtonImage src={Assets.LEFT_ARROW_ICON} />
        </StyledLinkDiv>
      </FlexContainer>
      <div>
        <Header>
          <Title>
            {type && t((type + 's') as string)} ({total ?? 0})
          </Title>

          <StyledPagination>
            <StyledEntity onKeyDown={enterKeyDown}>
              <Input
                label={t('search')}
                name="search"
                value={search}
                placeholder={t('name')}
                template={EInputTemplate.NAME}
                onChange={putTitle}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit)) ? searchPage : 1)
              }
            />
          </StyledPagination>

          <StyledPagination>
            <StyledEntity onKeyDown={enterKeyDown}>
              <Input
                label={t('page')}
                name="searchPage"
                value={searchPage.toString()}
                placeholder={t('page')}
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit)) ? searchPage : 1)
              }
            />
          </StyledPagination>

          <CreateButton
            to={{
              pathname: './create',
            }}
          >
            {t('create')}
          </CreateButton>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedPlaces}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>{t('back')}</StyledButton>
          )}

          <div>
            {currentPage}/
            {!total ? 1 : Math.ceil(total / paginationLimit) === 0 ? 1 : Math.ceil(total / paginationLimit)}
          </div>

          {currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>{t('next')}</StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  );
};

export default Clinics;
