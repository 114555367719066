import React, { FC, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';

import { createInfoAction, getInfoAction, getMainInfoSelector, updateInfoAction } from '../../store';
import { Divider, ELocales, EmptyTranslateForm, TLangForm, useTypedSelector } from '../../common';
import { languages } from "../Settings/consts";

import {
	Container,
	Header,
	StyledButton,
	StyledContainer,
	StyledDropDown,
	TextareaContainer,
	StyledText,
	Title
} from './styled';
import { TInit } from './types';

const InfoSection: FC = () => {

	const { information } = useTypedSelector(getMainInfoSelector);

	const init:TInit = {
		height: 350,
		width: '100%',
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor textcolor',
			'searchreplace visualblocks code fullscreen textcolor ',
			'insertdatetime media table paste code help wordcount image textcolor',
		],
		image_title: false,
		automatic_uploads: false,
		images_upload_url: 'postAcceptor.php',
		toolbar:
			'undo redo | formatselect | bold italic backcolor forecolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent | removeformat | image code',
	}

	const { t } = useTranslation();

	const FieldsName = {
		license: t('license.agreement'),
		rights: t('rights.obligations'),
		name: t('name')
	};

	const [nameBody, setNameBody] = useState<TLangForm>(EmptyTranslateForm)
	const [licenseBody, setLicenseBody] = useState<TLangForm>(EmptyTranslateForm)
  const [rightsBody, setRightsBody] = useState<TLangForm>(EmptyTranslateForm)

	useEffect(() => {
		setNameBody(information[0]?.body ?? '');
		setLicenseBody(information[1]?.body ?? '');
		setRightsBody(information[2]?.body ?? '');
	}, [information])

	const [language, setLanguage] = useState<string>(ELocales.default);

	const dispatch = useDispatch();

	const changeType = (type: string) => {
		setLanguage(type);
	};

	const inputMultiLangHandler = (text: string, setState:any) => {
		setState((prev:any) => ({
			...prev,
			[language]: text ,
		}));
	};



	const onCreateLicenseChanges = () => {
			const result = window.confirm(t('create.information?'));
			try {
				if (result) {
					dispatch(createInfoAction.request({
								title: {uk: FieldsName.license, en: FieldsName.license, es: FieldsName.license },
								body: {uk: licenseBody?.uk, en: licenseBody?.en, es: licenseBody?.es}
							},
						))
				}
			} catch (error) {
			}
		dispatch(getInfoAction.request({}))
	};

	const onCreateRightsChanges = () => {
		const result = window.confirm(t('create.information?'));
		try {
			if (result) {
				dispatch(createInfoAction.request({
						title: {uk: FieldsName.rights, en: FieldsName.rights, es: FieldsName.rights},
						body: {uk: rightsBody?.uk, en: rightsBody?.en, es: rightsBody?.es}
					},
				))
			}
		} catch (error) {
		}
		dispatch(getInfoAction.request({}))
	};

	const onCreateNameChanges = () => {
		const result = window.confirm(t('create.information?'));
		try {
			if (result) {
				dispatch(createInfoAction.request({
						title: {uk: FieldsName.name, en: FieldsName.name, es: FieldsName.name },
						body: {uk: nameBody?.uk, en: nameBody?.en, es: nameBody?.es}
					},
				))
			}
		} catch (error) {
		}
		dispatch(getInfoAction.request({}))
	};

	const onPressSaveLicenseChanges = () => {
		const result = window.confirm(t('update.information?'));
		try {
			if (result) {
				dispatch(updateInfoAction({
						title: {uk: FieldsName.license, en: FieldsName.license, es: FieldsName.license },
						body: {uk: licenseBody?.uk, en: licenseBody?.en, es: licenseBody?.es},
					  _id: information[1]?._id,
					  authorization: '',
					},
				))
			}
		} catch (error) {
		}
		dispatch(getInfoAction.request({}))
	};


	const onPressSaveRightsChanges = () => {
		const result = window.confirm(t('update.information?'));
		try {
			if (result) {
				dispatch(updateInfoAction({
						title:  {uk: FieldsName.rights, en: FieldsName.rights, es: FieldsName.rights},
						body: {uk: rightsBody?.uk, en: rightsBody?.en, es: rightsBody?.es},
					  _id: information[2]?._id,
					  authorization: '',
					},
				))
			}
		} catch (error) {
		}
		dispatch(getInfoAction.request({}))
	};

	const onPressSaveNameChanges = () => {
		const result = window.confirm(t('update.information?'));
		try {
			if (result) {
				dispatch(updateInfoAction({
						title:  {uk: FieldsName.name, en: FieldsName.name, es: FieldsName.name },
						body:  {uk: nameBody?.uk, en: nameBody?.en, es: nameBody?.es},
					  _id: information[0]?._id,
					  authorization: '',
					},
				))
			}
		} catch (error) {
		}
		dispatch(getInfoAction.request({}))
	};

	useEffect(() => {
		dispatch(getInfoAction.request({}))
	}, [])


	return (
		<Container>
			{information &&
				<>
					<Header>
						<Title>{t('information')}</Title>

						<StyledDropDown>
							<Dropdown
								options={languages}
								onChange={(option) => changeType(option.value)}
								value={language}
								placeholder="Виберите язык"
							/>
						</StyledDropDown>
					</Header>

					<Divider height={30}/>

					<TextareaContainer>
						<StyledText>{FieldsName.name}</StyledText>
						<Editor
							apiKey="ind2kqz86dttjxo6tngdvjw2spcwpjpzajoin3o7zwng85qg"
							value={nameBody[language as keyof typeof nameBody] ?? ''}
							init={init}
							onEditorChange={(text) => inputMultiLangHandler(text, setNameBody)}
						/>
					</TextareaContainer>

					<Divider height={10}/>

					<StyledContainer>
						<StyledButton
							onClick={information[0]?.body === '' ? onCreateNameChanges : onPressSaveNameChanges}
						>{t('save')}</StyledButton>
					</StyledContainer>

					<Divider height={30}/>

					<TextareaContainer>
						<StyledText>{FieldsName.license}</StyledText>
						<Editor
							apiKey="ind2kqz86dttjxo6tngdvjw2spcwpjpzajoin3o7zwng85qg"
							value={licenseBody[language as keyof typeof licenseBody] ?? ''}
							init={init}
							onEditorChange={(text) => inputMultiLangHandler(text, setLicenseBody)}
						/>
					</TextareaContainer>

					<Divider height={10}/>

					<StyledContainer>
						<StyledButton
							onClick={information[1]?.body === '' ? onCreateLicenseChanges : onPressSaveLicenseChanges}
						>{t('save')}</StyledButton>
					</StyledContainer>

					<Divider height={30}/>

					<TextareaContainer>
						<StyledText>{FieldsName.rights}</StyledText>
						<Editor
							apiKey="ind2kqz86dttjxo6tngdvjw2spcwpjpzajoin3o7zwng85qg"
							value={rightsBody[language as keyof typeof rightsBody] ?? ''}
							onEditorChange={(text) => inputMultiLangHandler(text, setRightsBody)}
							init={init}
						/>
					</TextareaContainer>

					<Divider height={10}/>

					<StyledContainer>
						<StyledButton
							onClick={information[2]?.body === '' ? onCreateRightsChanges : onPressSaveRightsChanges}
						>{t('save')}</StyledButton>
					</StyledContainer>

					<Divider height={30}/>
				</>
			}
		</Container>
	);
};

export default InfoSection;
