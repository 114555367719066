import { NavLink as NavLinks } from 'react-router-dom'
import styled from 'styled-components'
import { colors, FLEX, FONT, SHADOW } from '../../common'

export const TopbarWrapper = styled.div`
  width: 100%;
  padding: 5px 60px;

  height: 80px;

  background-color: ${colors.white};

  ${SHADOW({})};

  ${FLEX({
  align: 'center',
  justify: 'space-between',
  direction: 'row',
  wrap: 'nowrap',
})}
`

export const LogoIcon = styled.img`
  width: 70px;
  height: 70px;

  border-radius: 50%;
`

export const Logo = styled.p`
  ${FONT({
  size: '20px',
  weight: '700',
  align: 'center',
  color: colors.lightBlue,
})}
`

export const LayoutWrapper = styled.div`
  width: 100%;

  height: calc(100vh - 80px);

  ${FLEX({ align: 'flex-start', wrap: 'nowrap' })}
`

export const ContentWrapper = styled.div`
  width: calc(100vw - 270px);

  height: 100%;
  background: ${colors.darkWhite};
`

export const ContentInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 45px 30px;
  overflow: hidden;
  overflow-y: auto;
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;

  cursor: pointer;

  ${FLEX({})}
`

export const SidebarWrapper = styled.div`
  width: 270px;
  height: 100%;

  background: ${colors.white};

  ${FLEX({ direction: 'column', justify: 'space-between', wrap: 'nowrap' })}
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  padding: 45px 0;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  ${FLEX({
  direction: 'column',
  align: 'flex-end',
  justify: 'flex-start',
  wrap: 'nowrap',
})}
`

export const NavLink = styled(NavLinks)`
  width: calc(100% - 30px);
  outline: 0;
  padding: 20px 55px 20px 30px;

  cursor: pointer;

  text-decoration: none;
  transition: 0.15s ease-in-out;

  ${FLEX({ align: 'center', justify: 'flex-start' })}

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  &.active {
    color: ${colors.green};
    background-color: ${colors.darkWhite};
    border-radius: 50px 0 0 50px;
  }
`

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageBlock = styled.div``

export const MenuName = styled.div``

export const LogoutBtn = styled.div`
  margin-bottom: 25px;
  padding: 20px 55px 20px 30px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const LogoContainer = styled.div`
  ${FLEX({
  direction: 'row',
  wrap: 'nowrap',
  justify: 'flex-start',
  align: 'center',
})}
`
