import { i18n } from "../config";
import { LOCALE_KEY } from "../consts";
import { ELocales } from "../enums";

export const getLocale = (): ELocales => {
    return (localStorage.getItem(LOCALE_KEY) || ELocales.default) as ELocales;
}

export const setLocale = (locale: ELocales) => {
    localStorage.setItem(LOCALE_KEY, locale);
    i18n.changeLanguage(locale);
}