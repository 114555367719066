import styled from "styled-components";

export const Container = styled.div`
  position: relative;
	width: 20px;
`;

export const InputCheckbox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden; 
  clip: rect(0, 0, 0, 0);
`;

export const CheckboxBlock = styled.span`
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
	top: 2px;
	left: 0;
  border: 1.5px solid #1F166A;
  cursor: pointer;
`;

export const CheckStyle = styled.div`
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #1F166A;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;





