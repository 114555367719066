import { createAction } from "@reduxjs/toolkit";
import { createActionTypes, createApiActions } from "actions-util";
import { AxiosError } from "axios";

import {
    PLACE_CREATE,
    PLACE_EDIT,
    PLACE_GET_COUNT,
    PLACE_REMOVE,
    PLACE_SET_COUNT,
    PLACE_SET_LIST,
    PLACE_GET,
    PLACE_GET_LIST,
    PLACE_SET,
    PLACE_SET_STATE,
    GENERATE_QRCODE,
    REMOVE_PHOTO,
    REMOVE_DOCUMENT,
    GET_TRANSACTIONS,
    SET_TRANSACTIONS,
    GET_VISITED,
    SET_VISITED
} from "./consts";
import {
    GetPlacesActionResponse,
    TCreatePlacePyaload,
    TEditPlacePayload,
    TGenerateQrcodePayload,
    TGetPlaceCountPayload,
    TGetPlacePayload,
    TGetPlacesPayload,
    TGetTransactionsPyaload,
    TGetVisitedPyaload,
    TRemoveDocumentPayload,
    TRemovePhotoPayload,
    TRemovePlacePayload,
    TSetPlaceCountPayload,
    TSetPlaceListPayload,
    TSetPlacePayload,
    TSetPlaceStatePayload,
    TSetTransactions,
    TSetVisited
} from "./types";

export const placeActions = {
    getList: createAction(PLACE_GET_LIST,
        (payload: TGetPlacesPayload) => ({ payload })),

    get: createAction(PLACE_GET,
        (payload: TGetPlacePayload) => ({ payload })),

    set: createAction(PLACE_SET,
        (payload: TSetPlacePayload) => ({ payload })),

    setList: createAction(PLACE_SET_LIST,
        (payload: TSetPlaceListPayload) => ({ payload })),

    setState: createAction(PLACE_SET_STATE,
        (payload: TSetPlaceStatePayload) => ({ payload })),

    create: createAction(PLACE_CREATE,
        (payload: TCreatePlacePyaload) => ({ payload })),

    edit: createAction(PLACE_EDIT,
        (payload: TEditPlacePayload) => ({ payload })),

    remove: createAction(PLACE_REMOVE,
        (payload: TRemovePlacePayload) => ({ payload })),

    getCount: createAction(PLACE_GET_COUNT,
        (payload: TGetPlaceCountPayload) => ({ payload })),

    setCount: createAction(PLACE_SET_COUNT,
        (payload: TSetPlaceCountPayload) => ({ payload })),
    generateQrcode: createAction(GENERATE_QRCODE, (payload: TGenerateQrcodePayload) => ({ payload })),
    removePhoto: createAction(REMOVE_PHOTO, (payload: TRemovePhotoPayload) => ({ payload })),
    removeDocument: createAction(REMOVE_DOCUMENT, (payload: TRemoveDocumentPayload) => ({ payload })),
    getTransactions: createAction(GET_TRANSACTIONS, (payload: TGetTransactionsPyaload) => ({ payload })),
    setTransactions: createAction(SET_TRANSACTIONS, (payload: TSetTransactions) => ({ payload })),
    getVisited: createAction(GET_VISITED, (payload: TGetVisitedPyaload) => ({ payload })),
    setVisited: createAction(SET_VISITED, (payload: TSetVisited) => ({ payload }))
}


export const getPlaceListAction = createApiActions<TGetPlacesPayload, GetPlacesActionResponse, AxiosError>(
  createActionTypes('PLACE/GET_LIST')
);