import { FC } from 'react'
import {useTranslation} from "react-i18next";

import { StyledButton, Label } from './styled'
import { TButtonProps } from './types'

export const Button: FC<TButtonProps> = ({ onClick, title, type }) => {

  const { t } = useTranslation()

  return (
    <StyledButton type={type} onClick={onClick}>
      <Label>{t(title)}</Label>
    </StyledButton>
  )
}
