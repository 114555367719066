import styled from "styled-components";
import { colors, FLEX, FONT, SHADOW } from "../../common";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
	height: 100%;
	
  ${FLEX({ justify: 'space-between', direction: 'column', wrap: 'nowrap' })}
`

export const Image = styled.img`
  width: 16px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const Header = styled.div`
  width: 100%;

  padding: 25px;

  background: ${colors.white};

  ${FLEX({ justify: 'space-between', direction: 'row', wrap: 'nowrap' })}
`

export const Title = styled.h1`
  ${FONT({ size: '18px', color: colors.lightBlue, weight: '700' })}
`

export const TableWrapper = styled.div`
  width: 100%;

  padding-bottom: 10px;

  background: ${colors.white};

  overflow-y: scroll;
`

export const StyledRow = styled.tr`
  & td {
    padding: 9px 15px 7px 15px;
    width: 20%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${FONT({ size: '16px', color: colors.lightBlue, weight: '400' })}
`

export const StyledHeader = styled.th`
  padding: 15px;

  ${SHADOW({ x: 2, y: 0 })};

  ${FONT({
  size: '16px',
  color: colors.lightBlue,
  weight: '700',
  align: 'left',
})}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 30%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`

export const StyledButtonEnd = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${colors.lightGray};

  width: 30%;

  transition: 0.15s ease-in-out;

  ${FONT({ weight: '700', size: '16px', color: colors.lightGray })}

  ${FLEX({})}
`

export const StyledPagination = styled.div`
  margin-bottom: 10px;
  ${FLEX({ justify: 'space-between' })}
`

export const FullWidth = styled.div`
  width: 100%;
`

export const StyledEntity = styled.div`
  ${FONT({ size: '18px', color: colors.lightBlue, weight: '700' })}
`

export const StyledLink = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${colors.gray};
  }

  ${FONT({ weight: '700', size: '16px', color: colors.lightBlue })}

  ${FLEX({})}
`