import { dataType } from "./types";

export const data: dataType[] = [
	{
		title: 'users',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'entertainment',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'restaurants',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'hotels',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'skiResorts',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'shops',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'createAdministrator',
		items: ['create', 'read', 'update', 'delete']
	},
	{
		title: 'settings',
		items: ['create', 'read', 'update', 'delete']
	},
]