import { FC } from "react";

import { Container } from "./styled";
import { TScrollWindow } from "./types";

const ScrollWindow: FC<TScrollWindow> = ({ children }) => {

	return (
		<Container>
			{ children }
		</Container>
	)
}

export default ScrollWindow