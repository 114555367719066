import hidePasswordIcon from './icons/hidePassword.svg'
import showPasswordIcon from './icons/showPassword.svg'
import customersIcon from './icons/customers.svg'
import logoutIcon from './icons/logout.svg'
import leftArrowIcon from './icons/left-arrow.svg'
import petsIcon from './icons/pets_icon.svg'
import petsLogoIcon from './icons/pets_logo.png'
import settingsIcon from './icons/settings.svg'
import infoSectionIcon from './icons/info-section.svg'
import clinicsIcon from './icons/clinics.svg'
import downloadArrowIcon from './icons/downloadArrow.svg'
import cyanIcon from './icons/cyan.svg'
import searchIcon from './icons/search.svg'
import complaintIcon from './icons/complaint.svg'
import proposalIcon from './icons/proposal.svg'
import inaccuracyIcon from './icons/inaccuracy.svg'
import downArrowIcon from './icons/down-arrow.svg'
import upArrowIcon from './icons/up-arrow.svg'
import checkboxIcon from './icons/checkbox.svg'
import checkboxActiveIcon from './icons/checkboxActive.svg'
import controlIcon from './icons/control.svg'
import placesIcon from './icons/places.svg'
import notificationIcon from './icons/notification.svg'
import dashboardIcon from './icons/dashboard.svg'
import markets from './icons/markets.svg'
import skiResorts from './icons/ski_resorts.svg'
import hotels from './icons/hotels.svg'
import foodDelivery from './icons/food_delivery.svg'
import clown from './icons/clown.svg'
import admin from './icons/admin.svg'
import admins from './icons/admins.png'
import close from './icons/close.svg'

export class Assets {
    static readonly HIDE_PASSWORD_ICON = hidePasswordIcon

    static readonly SHOW_PASSWORD_ICON = showPasswordIcon

    static readonly CHECKBOX_ICON = checkboxIcon

    static readonly DASHBOARD_ICON = dashboardIcon

    static readonly NOTIFICATION_ICON = notificationIcon

    static readonly PLACES_ICON = placesIcon

    static readonly CONTROL_ICON = controlIcon

    static readonly CHECKBOX_ACTIVE_ICON = checkboxActiveIcon

    static readonly DOWN_ARROW_ICON = downArrowIcon

    static readonly UP_ARROW_ICON = upArrowIcon

    static readonly COMPLAINT_ICON = complaintIcon

    static readonly INACCURACY_ICON = inaccuracyIcon

    static readonly PROPOSAL_ICON = proposalIcon

    static readonly DOWNLOAD_ARROW_ICON = downloadArrowIcon

    static readonly SEARCH_ICON = searchIcon

    static readonly CYAN_ICON = cyanIcon

    static readonly CUSTOMERS_ICON = customersIcon

    static readonly CLINICS_ICON = clinicsIcon

    static readonly LOGOUT_ICON = logoutIcon

    static readonly LEFT_ARROW_ICON = leftArrowIcon

    static readonly PETS_ICON = petsIcon

    static readonly PETS_LOGO_ICON = petsLogoIcon

    static readonly SETTINGS_ICON = settingsIcon

    static readonly INFO_SECTION_ICON = infoSectionIcon

    static readonly MARKETS = markets

    static readonly SKI_RESORTS = skiResorts

    static readonly HOTELS = hotels

    static readonly FOOD_DELIVERY = foodDelivery

    static readonly CLOWN = clown

    static readonly ADMIN = admin

    static readonly ADMINS = admins

    static readonly CLOSE = close
}
