import { api } from "../../common";

export const ADMIN_URL = {
	login: `${api.withAPI}/api/auth/admin`,
	getAdmins: (
		page: number,
		limit: number,
		search: string
	) => `${api.withAPI}/api/admin?page=${page}&limit=${limit}&search=${search}`,
	getAdminById: (id: string) => `${api.withAPI}/api/admin/${id}`,
	createAdmin: `${api.withAPI}/api/admin`,
	delete_admin: `${api.withAPI}/api/admin/`,
	update_admin: `${api.withAPI}/api/admin/`,
}
