import { createReducer } from '@reduxjs/toolkit';
import { placeActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
	loading: false,
	response: null,
	place: null,
	places: {
		data: [],
		total: 0,
	},
	transactions: {
		data: [],
		total: null,
	},
	visited: {
		data: [],
		total: null,
	},
	count: null,
};

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(placeActions.set, (state, {payload}) => ({...state, ...payload}))
		.addCase(placeActions.setList, (state, {payload}) => ({...state, ...payload}))
		.addCase(placeActions.setState, (state, {payload}) => ({...state, response: null, ...payload}))
		.addCase(placeActions.setCount, (state, {payload}) => ({...state, ...payload}))
		.addCase(placeActions.setTransactions, (state, {payload}) => ({
			...state,
			// @ts-ignore
			transactions: {total: null, ...payload},
		}))
		.addCase(placeActions.setVisited, (state, {payload}) => ({...state, visited: {...payload}}));
});

export default reducer;
