import { api } from "../../common";
import { TPlaceType } from "./types";

export const PLACE_URL =
{
    get: `${api.withAPI}/api/admin/place`,
    get_list: (
      page: number,
      limit: number,
      type: TPlaceType,
      search: string
    ) =>
        `${api.withAPI}/api/admin/place?page=${page}&limit=${limit}&type=${type}&search=${search}`,
    create: `${api.withAPI}/api/admin/place`,
    edit: `${api.withAPI}/api/admin/place`,
    remove: `${api.withAPI}/api/admin/place`,
    count: `${api.withAPI}/api/admin/place/count`,
    addPhoto: `${api.withAPI}/api/admin/place/add-photo`,
    deletePhoto: `${api.withAPI}/api/admin/place/delete-photo`,
    addDocument: `${api.withAPI}/api/admin/place/add-document`,
    deleteDocument: `${api.withAPI}/api/admin/place/delete-document`,
    generateQrcode: `${api.withAPI}/api/admin/place/qrcode`,
    getTransactions: (id: string) => `${api.withAPI}/api/admin/transactions/place/${id}`,
    getVisited: (id: string) => `${api.withAPI}/api/admin/place/visited/${id}`
}