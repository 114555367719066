import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Assets, FlexContainer, useTypedSelector } from "../../common";
import { placeActions, getRootPlaceSelector } from "../../store/place";

import { Card, CardIcon, Info, Span } from "./styled";
import { ETypes } from "./types";


const Places: FC = () => {

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { count } = useTypedSelector(getRootPlaceSelector)
	const { admin } = useTypedSelector(state => state.admin)

	const Requests = {
		getTypesItemsCount: () => {
			dispatch(placeActions.getCount({}))
		}
	}

	useEffect(() => {
		Requests.getTypesItemsCount()
	}, [])

	useEffect(() => {
		console.log(count)
	})

	const data = useMemo(() =>
		[
			{
				href: ETypes.entartainments,
				permission: admin?.permissions?.entertainment?.read,
				asset: Assets.CLOWN,
				label: t('entertainment'),
				info: `${count?.entertainments} ${t('available')}`
			},
			{
				href: ETypes.restaurants,
				permission: admin?.permissions?.restaurants?.read,
				asset: Assets.FOOD_DELIVERY,
				label: t('restaurants'),
				info: `${count?.restaurants} ${t('available')}`

			},
			{
				href: ETypes.hotels,
				permission: admin?.permissions?.hotels?.read,
				asset: Assets.HOTELS,
				label: t('hotels'),
				info: `${count?.hotels} ${t('available')}`
			},
			{
				href: ETypes.ski_resorts,
				permission: admin?.permissions?.skiResorts?.read,
				asset: Assets.SKI_RESORTS,
				label: t('skiResorts'),
				info: `${count?.skiResorts} ${t('available')}`
			},
			{
				href: ETypes.shops,
				permission: admin?.permissions?.shops?.read,
				asset: Assets.MARKETS,
				label: t('shops'),
				info: `${count?.shops} ${t('available')}`
			}
		], [count])

	console.log(count)

	return (
		<FlexContainer gap={'30px'} direction='row'>

			{data.map((type, idx: number) => (
				<React.Fragment key={idx}>
					{
						!type.permission ?
							''
							:
							<Card href={`#/places/${type.href}`}>
								<FlexContainer gap={'40px'} justify='flex-start' width="90%">
									<CardIcon src={type.asset}/>
									<FlexContainer direction="column" width="auto" align="center" justify="center">
										<Span>{type.label}</Span>
										<Info>{type.info}</Info>
									</FlexContainer>
								</FlexContainer>
							</Card>
					}
				</React.Fragment>
			))}

		</FlexContainer>
	)
}


export default Places