import { takeLatest, call, put, select } from 'redux-saga/effects'
import { PromiseReturnType, TDataWrapper, TResponse } from '../types'
import {
	AdminActions,
	createAdminAction,
	getAdminsAction,
	getAdminByIdAction,
	deleteAdminAction,
	updateAdminAction
} from './actions'
import { ApiAdminService } from './api.service'
import { decodeToken } from "react-jwt";
import { AxiosError } from "axios";
import { take } from "@redux-saga/core/effects";

import { TInitialState, TLoginAdminPayload } from './types'
import { getMainAdminSelector } from './selectors';
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast, i18n } from "../../common";

function* loginWorker({ payload }: TDataWrapper<TLoginAdminPayload>) {

	try {

		const response: TResponse = yield call([ApiAdminService, ApiAdminService.login], {...payload})
		const access_token = response.data.access_token;
		const permissions = response.data.permissions;

		const data = decodeToken(access_token) as any;
		if (data) {
			yield put(AdminActions.set({admin: {...data, permissions: {...permissions}}, accessToken: access_token}))
		}
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t('authorization.error') as string)));
	}
}

function* logoutWorker() {
	yield put(AdminActions.set({admin: null, accessToken: null}))

}

export function* getAccessToken(): Generator<unknown, string, TInitialState> {
	const {accessToken} = yield select(getMainAdminSelector)
	return 'Bearer ' + accessToken as string;
}

function* getAdminsWorker({ payload }: ReturnType<typeof getAdminsAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiAdminService.getAdmins>> =
			yield call([ApiAdminService, ApiAdminService.getAdmins], {
				authorization: token,
				...payload,
			})

		yield put(
			getAdminsAction.success({
				admins: response.data.admins,
				total: response.data.total,
			}),
		)
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.administrators") as string)));
		yield put(getAdminsAction.failure(e as AxiosError));
	}
}

function* getAdminByIdWorker({payload}: { payload: { id: string } }) {
	try {
		const token: string = yield getAccessToken();

		const res: PromiseReturnType<ReturnType<typeof ApiAdminService.getAdminById>> = yield call(
			[ApiAdminService, ApiAdminService.getAdminById],
			{
				authorization: token,
				id: payload.id,
			}
		);
		yield put(getAdminByIdAction.success({data: res.data, status: 'ok'}));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.admin") as string)));

		yield put(getAdminByIdAction.failure(e as AxiosError));
	}
}


function* createAdminWorker({
	                            payload,
                            }: ReturnType<typeof createAdminAction['request']>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiAdminService.createAdmin>> =
			yield call([ApiAdminService, ApiAdminService.createAdmin], {
				authorization: token,
				...payload,
			})

		yield put(createAdminAction.success(response.data))

		yield put(
			showToastAction.request(
				generateSuccessToast(i18n.t("admin.was.created.successfully") as string),
			),
		)
	} catch (e: any) {
		yield put(
			showToastAction.request(
				generateErrorToast(
					e?.response?.data?.message ?? i18n.t("admin.creating.error"),
				),
			),
		)
		yield put(getAdminsAction.failure(e as AxiosError));
	}
}

function* updateAdminWorker({ payload }: ReturnType<typeof updateAdminAction>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiAdminService.updateAdmin>> = yield call(
			[ApiAdminService, ApiAdminService.updateAdmin],
			{
				permissions: payload.permissions,
				authorization: token,
				_id: payload._id,
			}
		);

		yield put(showToastAction.request(generateSuccessToast(i18n.t("admin.updated.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.administrator") as string)));
	}
}

function* deleteAdminWorker({ payload }: ReturnType<typeof deleteAdminAction>) {
	try {
		const token: string = yield getAccessToken();

		const response: PromiseReturnType<ReturnType<typeof ApiAdminService.deleteAdmin>> = yield call(
			[ApiAdminService, ApiAdminService.deleteAdmin],
			{
				_id: payload._id,
				authorization: token,
			}
		);

		if (response) {
			yield put(
				getAdminsAction.request({
					page: 1,
					limit: 20,
					search: '',
				})
			);

			yield take(getAdminsAction.success);

			yield put(showToastAction.request(generateSuccessToast(i18n.t("administrator.removed.successfully") as string)));
		}
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.administrator") as string)));
	}
}

export function* adminWatcher() {
	yield takeLatest(AdminActions.login, loginWorker);
	yield takeLatest(AdminActions.logout, logoutWorker);
	yield takeLatest(getAdminsAction.request, getAdminsWorker);
	yield takeLatest(getAdminByIdAction.request, getAdminByIdWorker);
	yield takeLatest(createAdminAction.request, createAdminWorker);
	yield takeLatest(updateAdminAction, updateAdminWorker);
	yield takeLatest(deleteAdminAction, deleteAdminWorker);
}