import styled from "styled-components";
import { colors } from "../../styles";


export const Container = styled.div`

  width: 100%;
  height: auto;
  min-height: 100px;
  max-height: 500px;
  background-color: ${colors.white};
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;

`