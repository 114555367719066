import { takeLatest, call, put, take } from '@redux-saga/core/effects';
import { AxiosError } from "axios";

import { showToastAction } from '../toasts';
import { getAccessToken } from '../admin';
import { generateErrorToast, generateSuccessToast, i18n } from '../../common';

import {
  createTransactionAction,
  deleteCustomerAction,
  getCustomerByIdAction,
  getCustomerEventsAction,
  getCustomersAction,
  setScannedQrcodeAction,
  transactionDone,
  updateCustomerAction,
} from './actions';
import { PromiseReturnType, TResponse } from '../types';
import { CustomerAPI } from './api.service';

function* getCustomerWorker({ payload }: ReturnType<typeof getCustomersAction['request']>) {
  try {
    const token: string = yield getAccessToken();

    const response: PromiseReturnType<ReturnType<typeof CustomerAPI.getCustomers>> = yield call(
      [CustomerAPI, CustomerAPI.getCustomers],
      {
        authorization: token,
        ...payload,
      }
    );

    yield put(
      getCustomersAction.success({
        customers: response.data.customers,
        total: response.data.total,
      })
    );
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.customers") as string)));
    yield put(getCustomersAction.failure(e as AxiosError));
  }
}

function* updateCustomerWorker({ payload }: ReturnType<typeof updateCustomerAction>) {
  try {
    const token: string = yield getAccessToken();

    const response: PromiseReturnType<ReturnType<typeof CustomerAPI.updateCustomer>> = yield call(
      [CustomerAPI, CustomerAPI.updateCustomer],
      {
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
        email: payload.email,
        authorization: token,
        _id: payload._id,
      }
    );

    yield put(showToastAction.request(generateSuccessToast(i18n.t("user.updated.successfully") as string)));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast(i18n.t('error.updating.customer') as string)));
  }
}

function* getCustomerByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield getAccessToken();

    const res: PromiseReturnType<ReturnType<typeof CustomerAPI.getCustomersById>> = yield call(
      [CustomerAPI, CustomerAPI.getCustomersById],
      {
        authorization: token,
        id: payload.id,
      }
    );

    yield put(getCustomerByIdAction.success({ data: res.data, status: 'ok' }));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.customer") as string)));

    yield put(getCustomerByIdAction.failure(e as AxiosError));
  }
}

function* getCustomerEventsWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield getAccessToken();
    const res: PromiseReturnType<ReturnType<typeof CustomerAPI.getCustomerEvents>> = yield call(
      [CustomerAPI, CustomerAPI.getCustomerEvents],
      {
        authorization: token,
        id: payload.id,
      }
    );

    yield put(getCustomerEventsAction.success({ data: res.data.event }));
  } catch (e) {
    yield put(getCustomerEventsAction.failure(e as any));
  }
}

function* deleteCustomerWorker({ payload }: ReturnType<typeof deleteCustomerAction>) {
  try {
    const token: string = yield getAccessToken();

    const response: PromiseReturnType<ReturnType<typeof CustomerAPI.deleteCustomer>> = yield call(
      [CustomerAPI, CustomerAPI.deleteCustomer],
      {
        _id: payload._id,
        authorization: token,
      }
    );

    if (response) {
      yield put(
        getCustomersAction.request({
          page: 1,
          limit: 20,
          search: '',
          sortBy: '',
        })
      );

      yield take(getCustomersAction.success);

      yield put(showToastAction.request(generateSuccessToast(i18n.t("customer.deleted.successfully") as string)));
    }
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast(i18n.t("error.deleting.customer") as string)));
  }
}

function* createTransactionWorker({ payload }: ReturnType<typeof createTransactionAction>) {
  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call([CustomerAPI, CustomerAPI.createTransaction], { token, ...payload });
    if (response) yield put(setScannedQrcodeAction({ scanned_qrcode: null }));
  } catch (e) {
    console.log('createTransaction', e);
  }

  yield put(transactionDone());
}

export function* customerWatcher() {
  yield takeLatest(getCustomersAction.request, getCustomerWorker);
  yield takeLatest(getCustomerByIdAction.request, getCustomerByIdWorker);
  yield takeLatest(getCustomerEventsAction.request, getCustomerEventsWorker);
  yield takeLatest(updateCustomerAction, updateCustomerWorker);
  yield takeLatest(deleteCustomerAction, deleteCustomerWorker);
  yield takeLatest(createTransactionAction, createTransactionWorker);
}
