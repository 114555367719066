import styled from 'styled-components'
import { FLEX } from '../../styles'
import { TStyledBackdropProps } from './types'

export const StyledBackdrop = styled.div<TStyledBackdropProps>`
  position: fixed;
  z-index: ${({ zIndex }) => zIndex ?? 1001};
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  overflow: hidden;

  background: rgba(0, 0, 0, 0.5);

  ${FLEX({})};
`
