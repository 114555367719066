import { FC } from "react";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from 'react-places-autocomplete';
import { StyledAutocomplete } from "../../styled";
import { TPlaceAutocomplete } from "./types";



const PlaceAutocomplete: FC<TPlaceAutocomplete> = ({ value, onChange, onPlaceSelect }) => {

    const { t } = useTranslation()

    return (
        <>
            <GooglePlacesAutocomplete
                value={value}
                onChange={onChange}
                onSelect={(name, place_id) => { onPlaceSelect && onPlaceSelect({ name, place_id }) }}

            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ width: '100%' }}>
                        <StyledAutocomplete
                            {...getInputProps({
                                placeholder: `${t('search.place')} ...`,
                                className: 'location-search-input',
                            })}
                        />

                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </GooglePlacesAutocomplete>
        </>
    )
}

export default PlaceAutocomplete