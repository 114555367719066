import React, { Fragment, FC } from 'react'
import { useTypedSelector } from '../../hooks'
import { Divider } from '../../components'
import { AlertsBar } from './styled'
import Context from './context'
import Toast from './Toast'
import { getToastSelector } from '../../../store'
import { TProvider } from './types'

const ToastsProvider: FC<TProvider> = ({ children }) => {
  const { toasts } = useTypedSelector(getToastSelector)

  return (
    <Context.Provider value={null}>
      {children}

      <AlertsBar>
        {/* @ts-ignore */}
        {toasts.map((toast, index) => (
          <Fragment key={`snackbar-alert-${index}`}>
            <>
              {!!index && <Divider height={5} />}
            </>
            <Toast data={toast} />
          </Fragment>
        ))}
      </AlertsBar>
    </Context.Provider>
  )
}

export default ToastsProvider
