import { AxiosResponse } from "axios";

import { PayloadAuth } from "../types";
import { setAuthorizationHeadersProp } from "../../common";
import { INFO_URL } from "./config";
import { HttpService } from "./http.service";
import {
	CreateInfoActionPayload,
	GetInfoActionPayload,
	GetInfoByIdActionPayload,
	GetInfoByIdActionResponse,
    UpdateInfoActionPayload
} from "./types";
import {UpdateCustomerActionPayload} from "../customers/types";
import {CUSTOMER_URL} from "../customers/config";

export class ApiInfoService extends HttpService {

	static getInfo(
		request: GetInfoActionPayload & PayloadAuth,
	): Promise<AxiosResponse<GetInfoByIdActionResponse>> {
		return this.request<GetInfoActionPayload>("GET_INFO",{
			url: INFO_URL.getInfo,
			method: 'GET',
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}

	static getInfoById(
		request: GetInfoByIdActionPayload & PayloadAuth,
	): Promise<AxiosResponse<GetInfoByIdActionResponse>> {
		return this.request<GetInfoByIdActionResponse>('GET_INFO_BY_ID',{
			url: INFO_URL.getInfoById(request.id),
			method: 'GET',
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}


	static createInfo(
		request: PayloadAuth & CreateInfoActionPayload,
	): Promise<AxiosResponse<GetInfoByIdActionResponse>> {
		return this.request<GetInfoByIdActionResponse>('CREATE_INFO',{
			url: INFO_URL.createInfo,
			method: 'POST',
			data: {
				body: request.body,
				title: request.title
			},
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}

	static updateInfo(
		request: UpdateInfoActionPayload,
	): Promise<AxiosResponse<void>> {
		return this.request('UPDATE_INFO', {
			url: `${INFO_URL.update_info}${request._id}`,
			method: 'PUT',
			data: {
				title: request.title,
				body: request.body,
			},
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}
}