import React from 'react'
import {
	SidebarWrapper,
	NavLink,
	MenuWrapper,
	Image,
	LogoutBtn,
	NavLinkBlock,
	ImageBlock,
	MenuName,
} from './styled'

import { useDispatch } from 'react-redux'
import { Assets, useTypedSelector } from '../../common'
import { AdminActions } from '../../store'
import { useTranslation } from 'react-i18next'

const fieldName = {
	Питомцы: 'pets',
	Пользователи: 'customers',
	Настройки: 'settings',
	Информация: 'info',
	Клиники: 'clinics',
	Жалобы: 'complaint',
	Предложения: 'proposal',
	Неточности: 'inaccuracy',
	Контроль: 'control',
	Места: 'places',
	Уведомления: 'notifications',
	Панель: 'dashboard',
}

export default function Sidebar({onMenuItemClick}: any) {
	const dispatch = useDispatch()

	const { admin } = useTypedSelector(store => store.admin)
	const {t} = useTranslation()

	const Events = {
		logout: () => {
			dispatch(AdminActions.logout())
		}
	}

	const sidebarMenus = [
		{
			name: t('users'),
			path: '/customers',
			exact: false,
			icon: Assets.CUSTOMERS_ICON,
			permission: admin?.permissions?.users?.read
		},
		{
			name: t('information'),
			path: '/info-section',
			exact: false,
			icon: Assets.INFO_SECTION_ICON,
			permission: admin?.permissions?.users?.read
		},
		{
			name: t('places'),
			path: '/places',
			exact: false,
			icon: Assets.PLACES_ICON,
			permission: true
		},
		{
			name: t('createAdministrator'),
			path: '/create-administrator',
			exact: false,
			icon: Assets.ADMIN,
			permission: admin?.permissions?.createAdministrator?.read
		},
		{
			name: t('administrators'),
			path: '/administrators',
			exact: false,
			icon: Assets.ADMINS,
			permission: admin?.permissions?.createAdministrator?.read
		},
		{
			name: t('settings'),
			path: '/settings',
			exact: false,
			icon: Assets.SETTINGS_ICON,
			permission: admin?.permissions?.settings?.read
		},
	]

	return (
		<SidebarWrapper>
			<MenuWrapper>
				{sidebarMenus.map((menu: any, index: number) => (
					  <React.Fragment key={index}>
						  { menu.permission ?
							  <NavLink
								  key={menu.name + index}
								  to={menu.path}
								  onClick={onMenuItemClick}
							  >
								  <NavLinkBlock>
									  <ImageBlock>
										  {menu.icon ? <Image src={menu.icon}/> : ''}
									  </ImageBlock>
									  <MenuName>
										  {menu.name}
									  </MenuName>
								  </NavLinkBlock>
							  </NavLink>
							  : ''
						  }
					  </React.Fragment>
						))
				}
			</MenuWrapper>

			<LogoutBtn onClick={Events.logout}>
				<Image src={Assets.LOGOUT_ICON}/>
				{t('exit')}
			</LogoutBtn>
		</SidebarWrapper>
	)
}
