export const PLACE_GET_LIST = 'PLACE/GET_LIST'
export const PLACE_SET_LIST = 'PLACE/SET_LIST'
export const PLACE_SET = 'PLACE/SET'
export const PLACE_SET_STATE = 'PLACE/SET_STATE'
export const PLACE_GET = 'PLACE/GET'
export const PLACE_REMOVE = 'PLACE/REMOVE'
export const PLACE_EDIT = 'PLACE/EDIT'
export const PLACE_CREATE = 'PLACE/CREATE'
export const PLACE_GET_COUNT = 'PLACE/GET_COUNT'
export const PLACE_SET_COUNT = 'PLACE/SET_COUNT'
export const GENERATE_QRCODE = 'PLACE/GENERATE_QRCODE'
export const REMOVE_PHOTO = 'PLACE/REMOVE_PHOTO'
export const REMOVE_DOCUMENT = 'PLACE/REMOVE_DOCUMENT'
export const GET_TRANSACTIONS = 'PLACE/GET_TRANSACTIONS'
export const SET_TRANSACTIONS = 'PLACE/SET_TRANSACTIONS'
export const GET_VISITED = 'PLACE/GET_VISITED'
export const SET_VISITED = 'PLACE/SET_VISITED'

export enum EPlacesResponse {
    CREATE,
    REMOVE,
    EDIT,
    ERROR,
    QR_CODE_GENERATED
}