import { AxiosResponse } from "axios";
import { setAuthorizationHeadersProp } from "../../common";
import { PayloadAuth, TResponse } from "../types";
import { ADMIN_URL } from "./config";
import { HttpService } from "./http.service";
import {
	CreateAdminActionPayload, DeleteAdminActionPayload,
	GetAdminByIdActionPayload,
	GetAdminByIdActionResponse,
	GetAdminsActionPayload,
	TLoginAdminRequestPayload, UpdateAdminActionPayload
} from "./types";

export class ApiAdminService extends HttpService {
	static login(data: TLoginAdminRequestPayload): Promise<AxiosResponse<TResponse>> {

		return this.request<TResponse>("LOGIN",{
			url: ADMIN_URL.login,
			method: 'POST',
			data
		});
	}

	static getAdmins(
		request: GetAdminsActionPayload & PayloadAuth,
	): Promise<AxiosResponse<GetAdminByIdActionResponse>> {
		return this.request<GetAdminByIdActionResponse>("GET_ADMINS",{
			url: ADMIN_URL.getAdmins(
				request.page,
				request.limit,
				request.search,
			),
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}

	static getAdminById(
		request: GetAdminByIdActionPayload & PayloadAuth,
	): Promise<AxiosResponse<GetAdminByIdActionResponse>> {
		return this.request<GetAdminByIdActionResponse>('GET_ADMIN_BY_ID',{
			url: ADMIN_URL.getAdminById(request.id),
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}


	static createAdmin(
		request: PayloadAuth & CreateAdminActionPayload,
	): Promise<AxiosResponse<GetAdminByIdActionResponse>> {
		return this.request<GetAdminByIdActionResponse>('CREATE_ADMIN',{
			url: ADMIN_URL.createAdmin,
			method: 'POST',
			data: {
				email: request.email,
				password: request.password,
				permissions: request.permissions
			},
			headers: {
				'Content-Type': 'application/json',
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}

	static updateAdmin(
		request: UpdateAdminActionPayload,
	): Promise<AxiosResponse<void>> {
		return this.request('UPDATE_ADMIN', {
			url: `${ADMIN_URL.update_admin}${request._id}`,
			method: 'PATCH',
			data: {
				email: request.email,
				// password: request.password,
				permissions: request.permissions
			},
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}

	static deleteAdmin(
		request: DeleteAdminActionPayload,
	): Promise<AxiosResponse<void>> {
		return this.request('DELETE_ADMIN', {
			url: `${ADMIN_URL.delete_admin}${request._id}`,
			method: 'DELETE',
			headers: {
				...setAuthorizationHeadersProp(request.authorization),
			},
		})
	}
}